import React, {useEffect} from "react";
import {Box, Flex, Text, ScrollArea, Spinner} from "@radix-ui/themes";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Dashboard from "../features/Dashboard/Dashboard";
import BottomNav from "./BottomNav";

import BarsList from "../features/Bars/BarsList";
import Header from "./Header";
import EntertainmentList from "../features/Entertainment/EntertainmentList";
import BookingModal from "../features/booking/BookingModal";
import RelaxList from "../features/Relax/RelaxList";
import TicketsScreen from "../features/Tickets/TicketsScreen";
import AccommodationList from "../features/Accommodation/AccommodationList";
import GlampingList from "../features/Accommodation/GlampingList";
import NotFound from "../features/NotFound/NotFound";
import CampingList from "../features/Accommodation/CampingList";
import ThermalHotelList from "../features/Accommodation/ThermalHotelList";
import AHomeRoomsList from "../features/Accommodation/AHomeRoomsList";
import AHomeRoomsThreeList from "../features/Accommodation/AHomeRoomsThreeList";
import AHomeRoomsSixList from "../features/Accommodation/AHomeRoomsSixList";
import Login from "../features/Login/Login";
import Profile from "../features/Profile/Profile";
import ReservationsList from "../features/Reservations/ReservationsList";
import PaymentRedirect from "../features/PaymentRedirect/PaymentRedirect";
import Register from "../features/Register/Register";
import SuccessfulRegisterScreen from "../features/Register/SuccessfulRegisterScreen";
import {noAuthToInitiate, reInitiateStore, setAuthProgress} from "../features/Login/authSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getAuthStore, saveAuthStore} from "../helpers/utils";
import {RootState} from "../store/store";
import ForgotPassword from "../features/Login/ForgotPassword";
import PublicOffer from "../features/PublicOffer/PublicOffer";
import SapKayakCatamaranList from "../features/Entertainment/SapKayakCatamaranList";
import LoungersList from "../features/Relax/LoungersList";
import HRLogo from "../HR_Logo.svg";

import './layout.css'
import ProtectedRoute from "../helpers/ProtectedRoute";
import LinkPayment from "../features/ScanQR/LinkPayment";
import PublicRules from "../features/PublicOffer/PublicRules";

export default function Layout() {

    // @ts-ignore
    const isFullScreen: boolean | null = window.navigator.standalone;

    const dispatch = useAppDispatch();

    const authentication = useAppSelector((state: RootState) => state.authentication);

    // store session on every change: logout, login, token refresh
    useEffect(() => {
        (async () => {
            if (!authentication.initiatedFromStorage) {
                return;
            }

            await saveAuthStore(authentication);
        })();
    }, [JSON.stringify(authentication)]);

    useEffect(() => {
        (async () => {
            const store = await getAuthStore();

            if (!store) {
                dispatch(noAuthToInitiate());
                setTimeout(() => dispatch(setAuthProgress(false)), 500);
                return;
            }

            dispatch(reInitiateStore(store));
            setTimeout(() => dispatch(setAuthProgress(false)), 500)
        })();
    }, []);

    if (authentication.authInProgress) {
        return (
            <Flex height="100dvh" direction="column" justify="center" align="center">
                <img src={HRLogo} height='64px' />
                <Flex mt="4" direction="column" align="center" gap="1">
                    <Spinner size="3" />
                    <Text size="1">Завантаження...</Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex
            direction="column"
            justify="between"
            height="100dvh"
            // position="relative"
        >
            <BrowserRouter>
                    <ScrollArea scrollbars="vertical" style={{
                        backgroundColor: 'var(--accent-1)',
                        //backgroundImage: `url(${Background})`
                    }}>
                        <Header />
                        <Box px='4' style={{paddingBottom: isFullScreen ? 68+16 : 56+16}}>

                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/register/success" element={<SuccessfulRegisterScreen />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/public-offer" element={<PublicOffer />} />
                                <Route path="/public-rules" element={<PublicRules />} />

                                <Route path="/entry-ticket" element={
                                    <ProtectedRoute>
                                        <TicketsScreen />
                                    </ProtectedRoute>
                                } />

                                <Route path="/reservations-list" element={
                                    <ProtectedRoute>
                                        <ReservationsList />
                                    </ProtectedRoute>
                                } />

                                <Route path="/link-payment/:reservationID" element={ // scanning QR from admin
                                    <ProtectedRoute>
                                        <LinkPayment />
                                    </ProtectedRoute>
                                } />

                                <Route path="/payment-redirect" element={<PaymentRedirect />} />

                                <Route path="/bars-list" element={<BarsList />} />
                                <Route path="/entertainment-list" element={<EntertainmentList />} />
                                <Route path="/sap-kayak-list" element={<SapKayakCatamaranList />} />
                                <Route path="/relax-list" element={<RelaxList />} />
                                <Route path="/relax-list/loungers" element={<LoungersList />} />

                                <Route path="/accommodation" element={<AccommodationList />} />
                                <Route path="/accommodation/glamping" element={<GlampingList />} />
                                <Route path="/accommodation/camping" element={<CampingList />} />
                                <Route path="/accommodation/hotel-thermal" element={<ThermalHotelList />} />
                                <Route path="/accommodation/ahome" element={<AHomeRoomsList />} />
                                <Route path="/accommodation/ahome/three" element={<AHomeRoomsThreeList />} />
                                <Route path="/accommodation/ahome/six" element={<AHomeRoomsSixList />} />

                                <Route path="*" element={<NotFound />} />
                            </Routes>

                        </Box>
                    </ScrollArea>

                    <BookingModal />
                    <BottomNav />

            </BrowserRouter>
        </Flex>
    )
}
