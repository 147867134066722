import React, {useEffect} from "react";
import {Box, Flex, TextField, Text, Heading, Card, Button, Spinner, Link} from "@radix-ui/themes";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useGetProfileQuery, useUpdateProfileMutation} from "./profileAPI";
import {logoutUser} from "../Login/authSlice";
import {ArrowLeftEndOnRectangleIcon, ArrowPathIcon} from "@heroicons/react/16/solid";
import {useNavigate} from "react-router-dom";
import {setEmail, setFirstName, setLastName, setPassword} from "./profileSlice";
import {RootState} from "../../store/store";
import {useFormValidation} from "../../helpers/validation";
import {rulesProfileForm, validationErrorState, ValidationKeys} from "./profileValidationRules";
import {emptySplitApi} from "../../api/api";

export default function Profile() {

    const {data} = useGetProfileQuery(null);
    const [updateProfileRequest, { isLoading: isUpdating, }] = useUpdateProfileMutation();

    const { lastName, firstName, email, password } = useAppSelector((state: RootState) => state.profile);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        rulesProfileForm,
        validationErrorState,
        {
            firstName,
            lastName,
            email,
            password,
        },
    );

    const onLogout = () => {
        dispatch(logoutUser());
        // in admin it invalidates on login
        // todo: invalidate all future tags
        // dispatch(emptySplitApi.util.invalidateTags(['CustomerProfile', 'ReservationsList']))

        navigate('/');
        window.location.reload(); // hack for invalidation
    };

    useEffect(() => {
        if (! data) return;

        dispatch(setFirstName(data.firstName ?? ''));
        dispatch(setLastName(data.lastName ?? ''));
        dispatch(setEmail(data.email ?? ''));
        dispatch(setPassword(''));
    }, [data]);

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        const response = await updateProfileRequest({
            firstName,
            lastName,
            password: password ? password : null,
            email,
        });

        // // @ts-ignore
        // if (response.data && response.data.accessToken) {
        //     navigate('/')
        // }
    };

    return (
        <Flex direction='column' pt='4'>
            <Heading>Ваш профіль</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    <Card size="2" variant="classic" className="dashboard-feature__card">
                        <Flex direction='column' gap='4'>
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Імʼя
                                </Text>
                                <TextField.Root
                                    placeholder="Валерій"
                                    value={firstName}
                                    type="text"
                                    onChange={(e) => {
                                        dispatch(setFirstName(e.target.value))
                                        onValidationInputChange('firstName');
                                    }}
                                />
                                <Text size="1" color="crimson">{validationState.firstName.errorMessage}</Text>
                            </label>
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Прізвище
                                </Text>
                                <TextField.Root
                                    placeholder="Котигорошко"
                                    value={lastName}
                                    type="text"
                                    onChange={(e) => {
                                        dispatch(setLastName(e.target.value))
                                        onValidationInputChange('lastName');
                                    }}
                                />
                                <Text size="1" color="crimson">{validationState.lastName.errorMessage}</Text>
                            </label>
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Номер телефону
                                </Text>
                                <TextField.Root
                                    type="tel"
                                    value={data ? data.phone : ''}
                                    disabled
                                    onChange={() => {}}
                                    placeholder="Ваш номер"
                                />
                            </label>
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    E-mail
                                </Text>
                                <TextField.Root
                                    type="email"
                                    value={email}
                                    placeholder="example@gmail.com"
                                    onChange={(e) => {
                                        dispatch(setEmail(e.target.value))
                                        onValidationInputChange('email');
                                    }}
                                />
                                <Text size="1" color="crimson">{validationState.email.errorMessage}</Text>
                            </label>
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Новий пароль
                                </Text>
                                <TextField.Root
                                    placeholder="*****"
                                    value={password}
                                    type="password"
                                    onChange={(e) => {
                                        dispatch(setPassword(e.target.value))
                                        onValidationInputChange('password');
                                    }}
                                />
                                <Text size="1" color="crimson">{validationState.password.errorMessage}</Text>
                            </label>
                            <Flex justify="between">
                                <Button color="red" onClick={onLogout}>
                                    <ArrowLeftEndOnRectangleIcon width="16" height="16" />
                                    Вийти
                                </Button>
                                <Button disabled={isUpdating} onClick={onSubmit}>
                                    {isUpdating ? <Spinner /> : <ArrowPathIcon width="16" height="16" />}
                                    Зберегти
                                </Button>
                            </Flex>
                        </Flex>
                    </Card>

                    <Flex justify="end" mt="4">
                        <Flex direction="column" gap="2">
                            <Link
                                size="2"
                                onClick={() => navigate('/public-rules')}
                                style={{ textDecoration: 'underline' }}
                            >
                                Правила комплексу
                            </Link>
                            <Link
                                size="2"
                                onClick={() => navigate('/public-offer')}
                                style={{ textDecoration: 'underline' }}
                            >
                                Публічна оферта
                            </Link>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    )
}
