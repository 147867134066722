export const iframeText = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.5pt 42.5pt 42.5pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:16.0pt;line-height:107%;font-family:"Times New Roman",serif'>ПАМ’ЯТКА
ДЛЯ ГОСТЕЙ КОМПЛЕКСУ «LAKE RESORT»</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:16.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>1.
ЗАГАЛЬНІ ПОЛОЖЕННЯ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Комплекс «Lake Resort» діє згідно з
Законами України та Правилами користування готелями й аналогічними засобами
тимчасового розміщення. Комплекс створює необхідні умови для проживання,
харчування, культурного дозвілля та відпочинку гостей. </span><span
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'><br>
</span><span lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'><br>
Комплекс – Комплекс заміського відпочинку «Lake resort»</span><span
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>.</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
"Times New Roman",serif'>Адміністратор – уповноважена особа комплексу, яка
слідкує за порядком на території комплексу і має право заборонити, стягнути
штраф, відмовити у наданні послуг.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'><br>
Дані правила встановлюють внутрішній розпорядок, правила користування майном, а
також правила поведінки:</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 1.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
При знаходженні на території Комплексу бути ввічливим і уважним.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 2</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>.
Паркування транспорту здійснюється, виключно, у спеціально відведених місцях.
При паркування автомобілів не загороджувати прохід пішоходам і проїзд іншому
транспорту.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 3</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>.
Не вживати нецензурні вирази в своєму спілкуванні (як з відпочиваючими, так і з
персоналом Комплексу).</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 4.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Зберігати речі в спеціально відведеному місці</span><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>.
П</span><span lang=UK style='font-size:12.0pt;line-height:107%;font-family:
"Times New Roman",serif'>ри відсутності такого, особисто стежити за власними
речами.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 5.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Не залишати дітей без нагляду.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 6.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Дотримуватись чистоти і порядку на території Комплексу і місцях громадського
користування. Не кидати сміття в невідведених для цих цілей місцях.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 7.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
У разі виявлення підозрілих предметів, не торкатися до даних предметів, а
повідомити про це негайно Адміністрації Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 8.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Уважно слухати і слідувати вказівкам і рекомендаціям персоналу Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 9.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Не приносити на територію Комплексу вибухові, вогненебезпечні, отруйні, а також
інші речовини, які становлять небезпеку для оточуючих.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 10.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Не вживати алкогольні та слабоалкогольні напої на території Комплексу, </span><span
lang=RU style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>за
</span><span lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>виключенням
відведених для цього місць, до яких відносяться території закладів харчування
Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 11.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Не палити на території Комплексу, </span><span lang=RU style='font-size:12.0pt;
line-height:107%;font-family:"Times New Roman",serif'>за </span><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>виключенням
спеціально відведених місць для паління.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 12.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'> Обережно
ставитися до будь-якого майна Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 13.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Всі свої побажання і претензії можна висловити персоналу Комплексу або
звернутися до Адміністрації Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 14.</span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
На території Комплексу категорично забороняються будь-які види дискримінації і
прояви неповаги через відмінності за національністю, віросповіданням, віком,
соціальним станом або іншими ознаками.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 1</span></b><b><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>5</span></b><b><span
lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>.</span></b><span
lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
Дбайливо ставитись до навколишнього середовища: не смітити, не обривати листя,
не зривати рослини; не засмічувати водойми.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Правило 16. </span></b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>Перебувати
на території комплексу можливо ТІЛЬКИ після ознайомлень з правилами
перебування, оплаті вхідного квитка або послуги, яка надає вхідний квиток.</span><span
lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>
</span></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Адміністрація не несе відповідальності за
втрату речей, залишених без нагляду на території Комплексу. </span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Заборонено винос меблів та інвентарю з
території Комплексу. Відпочиваючі, що псують меблі та інше обладнання та інвентар
будуть зобов'язані відшкодувати збитки і заплатити штраф (відповідно до
прейскуранта).</span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Комплекс надає послуги в тому обсязі та
якості, в яких вони можуть бути надані в кожний конкретний момент відповідно
до технічних, операційних та інших можливостей Комплексу. Здійснюючи оплату
/ передоплату проживання у Комплексі Ви заздалегідь приймаєте послуги
Комплексу , що передбачені цими Правилами та Договором публічної оферти.
Однак, ми завжди радо приймаємо Ваші побажання та рекомендації щодо покращення
відпочинку. Комплекс, зі свого боку, докладає максимум зусиль для створення
якнайкращих умов для Вашого проживання.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>2.
ПРАВИЛА КОРИСТУВАННЯ МАЙНОМ</span></b></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Відпочиваючі повинні дбайливо ставитися до
майна Комплексу, використовувати обладнання за призначенням, дотримуватися
вимог пожежної безпеки. У разі виявлення пошкодження майна, технічних
несправностей, надзвичайних ситуацій (затоплення, загоряння, розбиття скла
тощо) необхідно негайно повідомити про це адміністратора або охорону Комплексу.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>У разі пошкодження чи втрати майна
Комплексу, гість відшкодовує вартість завданих збитків відповідно до акту,
виставленим Адміністрацією Комплексу. При цьому збиток, нанесений
відпочиваючими, відшкодовується на місці в повному обсязі вартості пошкодженого
майна. Збиток, який виник внаслідок втрати або пошкодження майна Комплексу,
відшкодовується відпочиваючим в повному обсязі, згідно прейскуранта.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>За дотриманням внутрішнього розпорядку
Комплексу стежить Адміністратор. Він надає відпочиваючим роз'яснення щодо
правил проживання, приймає скарги від них на дії персоналу і інших
відпочиваючих, які порушують встановлений порядок роботи. </span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Відпочиваючий зобов'язаний відноситься до
майна Комплексу дбайливо.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Відпочивальник несе майнову
відповідальність за користування і за взяте в оренду майно.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>У разі втрати або пошкодження майна,
нанесення матеріальних збитків Комплексу, відпочивальник зобов'язаний
відшкодувати заподіяну шкоду.</span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>За травми, тілесні ушкодження і нещасні
випадки, отримані в процесі активного відпочинку, Адміністрація Комплексу
відповідальності не несе. Відпочиваючий є відповідальним за особисту безпеку і
безпеку оточуючих його осіб. У разі, якщо в процес ігри гравцем (відпочиваючим)
буде завдано якоїсь шкоди здоров'ю або майну інших гравців (відпочиваючих),
врегулювання даної ситуації, відбувається безпосередньо між відпочиваючими, без
участі Комплексу.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Забороняється:</span></b></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Палити в приміщеннях комплексу. Штраф 500
грн.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Заносити в приміщення зброю,
вогненебезпечні та легкозаймисті речовини, вибухові засоби, а також габаритні
предмети.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Переміщувати меблі й інше майно без
дозволу адміністрації.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Використовувати на території будь-які
прилади для приготування їжі, крім стаціонарних мангалів комплексу.</span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Приготування страв дозволяється тільки на
стаціонарних мангалах </span><span style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>К</span><span lang=UK style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>омплексу з
дотриманням правил пожежної безпеки. У разі заподіяння матеріальних збитків,
винний відшкодовує втрати.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>3.
ВНУТРІШНІЙ РОЗПОРЯДОК НА ТЕРИТОРІЇ КОМПЛЕКСУ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Адміністратор </span><span
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>К</span><span
lang=UK style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>омплексу
стежить за дотриманням внутрішнього розпорядку.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Забороняється:</span></b></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Розпалювати вогнища та користуватися
піротехнічними засобами і вогнепальною зброєю. В тому числі готування їжі у
невідведених для цього місцях.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Турбувати інших відпочиваючих, порушувати
правила поведінки в громадських місцях.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Привозити та використовувати акустичні
прилади з посилювачами звуку.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Гучно розмовляти, співати, вмикати музику
й телевізори з 23:00 до 8:00.</span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Привозити на територію свою їжу і напої
(виключення: легкі снеки, питна вода, закуски).</span></p>

<p class=MsoNormal align=center style='text-align:center'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'><br>
<b>Категорично забороняється (зона басейну):</b></span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• стрибати з бортів басейну, користуватися
ластами. У басейн необхідно спускатися тільки</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>сходами (сходах);</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>­• приносити в басейну миючі засоби в
склі, дзеркала, а також користуватися перед відвідуванням басейну мазями,
маслами і косметикою;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• під час плавання бути одягненим в
окуляри, ювелірні вироби та декоративні прикраси;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• користуватися басейнами особам, які
мають ознаки інфекційних захворювань, інфекційних хвороб шкіри, офтальмологічні
захворювання, відкриті рани, діагнози - епілепсія або інші психічні розлади,
ознаки недостатньої особистої гігієни;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• створювати перешкоди на воді іншим
відвідувачам;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• перебувати у верхньому одязі та у
вуличному взутті.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• під час плавання в басейнах жувати
жувальну гумку;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• занурюватися в воду на затриманому
диханні;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• виливати в ванну басейнів миючі або
косметичні засоби;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• приносити у басейни продукти і напої;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• перебувати в басейнах в нетверезому
стані або під впливом наркотичних речовин;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• Проносити на територію басейну:</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- предмети, що б'ються (скло, посуд);</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- всі види зброї: вибухові, легкозаймисті,
отруйні, радіоактивні й токсичні речовини, засоби</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>індивідуального захисту (газові балончики,
електрошокери та ін.);</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- аудіоапаратуру та інші джерела гучного
шуму;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- колючо-ріжучі предмети, предмети зі скла
та інші крихкі предмети, з яких можливе утворення</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>уламків (крім сонцезахисних окулярів);</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- алкоголь і наркотичні речовини;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- ліки та медикаменти;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• курити на території басейну;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• здійснювати стрибки в басейни з борта,
бігати і стрибати навколо ванни басейну;</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>• справляти природні потреби в ванну
басейну.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>4.
ЗАЇЗД І ПАРКУВАННЯ АВТОТРАНСПОРТУ</span></b></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Заїзд на територію Комплексу можливий лише
при наявності онлайн реєстрації у застосунку і оплати однієї з послуг, крім
оренди шезлонгів, басейну і риболовлі (сама по собі оплата оренди шезлонгів,
басейну або риболовлі - не дає права в’їзду на територію комплексу).</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Заїзд територію пляжу заборонений.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Паркування можливе лише у спеціально
відведених місцях.</span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Пересування територією Комплексу зі
швидкістю понад 20 км/год заборонено.</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>5.
ЗБЕРЕЖЕННЯ РЕСУРСІВ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Виходячи з номеру, гості повинні вимкнути
всі електроприлади, крім холодильників. У номерах забороняється використовувати
трійники й подовжувачі, будь-які привезені з собою електроприлади.
Забороняється залишати без нагляду відкриті водопровідні крани.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>6.
РЕСТОРАН І БАРИ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Приносити з собою й вживати на території
ресторану й барів алкогольні й безалкогольні напої та продукти харчування
заборонено. Заклади не надають послуг зі зберігання й приготування страв із
принесених продуктів. Послуги з обслуговування в номерах і альтанках не
надаються.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>7.
ПРОЖИВАННЯ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Час заселення – 14:00. Час виселення –
12:00. При виселенні звільняються номери, альтанки й місця паркування
автомобілів. </span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>ЗАКЛЮЧНІ
ПОЛОЖЕННЯ</span></b></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- Комплекс має право самостійно в цілях
безпеки визначати і встановлювати обмеження щодо</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>допуску осіб на окремі види послуг.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- Адміністрація Комплексу має право на
території Комплексу вводити заходи і обмеження,</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>спрямовані на посилення безпеки
відпочиваючих.</span></p>

<p class=MsoNormal><span lang=UK style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>- Дані правила спрямовані на забезпечення
безпеки, комфорт і здоров'я відвідувачів.</span></p>

</div>

</body>

</html>
`
