import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {useNavigate} from "react-router-dom";
import {STATIC_URL} from "../../api/config";

export default function AccommodationList() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Розміщення</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    heroImage={`${STATIC_URL}/images/glamping/GLEMPING_MAIN_PHOTO.png`}
                    name="Глемпінг"
                    description="Купольні будиночки у сосновому лісі на березі озера стануть ідеальним поєднанням комфорту та близкості до природи. Заїзд: з 14:00. Виїзд: до 12:00"
                    callToAction="Обрати"
                    onClickCTA={() => navigate('/accommodation/glamping')}
                    isHotelBooking
                    showPrices={false}
                />

                <EntertainmentEntry
                    heroImage={`${STATIC_URL}/images/camping/tent_with_guitar_hero_image.jpg`}
                    name="Кемпінг"
                    description="Природа, чисте повітря і спокій — ідеальний рецепт для душевного відновлення. Відкрийте для себе кемпінг на березі озера з LAKE RESORT ⛺️ Розпалюйте багаття у 2-х метровій ватрі, встановлюйте палатки, смажте барбекю, освіжіться в озері та насолоджуєтесь відпочинком на повну."
                    callToAction="Обрати"
                    onClickCTA={() => navigate('/accommodation/camping')}
                    isHotelBooking
                    showPrices={false}
                />

                <EntertainmentEntry
                    heroImage={`${STATIC_URL}/images/a_home/a_home_hero_image.jpg`}
                    name="A-Home"
                    description={`Затишні A-frame апарт-будиночки з чаном та сауною на березі озера для незабутнього відпочинку у будь-яку пору року. Чекаємо вас у A HOME🧡`}
                    callToAction="Обрати" 
                    onClickCTA={() => navigate('/accommodation/ahome')}
                    isHotelBooking
                    showPrices={false}
                />

                <EntertainmentEntry
                    disabledDescription="Доступно незабаром"
                    heroImage=""
                    name="Lake Hotel"
                    description=""
                    callToAction="Обрати"
                    onClickCTA={() => navigate('/accommodation/hotel-thermal')}
                    isHotelBooking
                    showPrices={false}
                />
            </Flex>
        </Flex>
    );
}
