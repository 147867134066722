import {Flex, IconButton, Text} from "@radix-ui/themes";
import React, {ReactElement} from "react";

interface BottomNavElementProps {
    onClick?: () => void,
    label: string,
    icon: ReactElement,
}
export default function BottomNavElement(props: BottomNavElementProps) {

    const {label, icon, onClick} = props;
    return (
        <Flex direction="column" align="center">
            <IconButton onClick={onClick} size="1" variant="outline" style={{ boxShadow: "none" }}>
                {React.cloneElement(icon,{width: '100%', height: '100%'})}
            </IconButton>
            <Text size="1" weight="bold" style={{ color: 'var(--accent-11)'}}>{label}</Text>
        </Flex>
    )
}
