import React from "react";
import {Box, Flex, Heading} from "@radix-ui/themes";
import { iframeText } from './publicOfferDoc';

// convert with cloudconvert
export default function PublicOffer() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Публічна оферта</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%' height='70dvh'>
                    <iframe title="Публічна оферта" style={{ width: '100%', height: '100%' }} srcDoc={iframeText} />
                </Box>
            </Flex>
        </Flex>
    )
}
