import React from "react";
import {Box, Button, Card, Flex, Heading, Text} from "@radix-ui/themes";
import {useNavigate} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/24/outline";

export default function PaymentRedirect() {
    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Статус оплати</Heading>

            <Box mt="6">
                <Card size="2" variant="classic" className="dashboard-feature__card">
                    <Flex direction="column" gap="1" align="center">
                        <CheckCircleIcon color="var(--teal-9)" width="36px" height="36px" />
                        <Heading size="3">Оплата пройшла успішно!</Heading>
                        <Text size="2" align="center">Оновлення статусу оплати може зайняти декілька хвилин.</Text>
                        <Button
                            mt="6"
                            onClick={() => navigate('/reservations-list')}
                        >
                            До резервацій
                        </Button>
                    </Flex>
                </Card>
            </Box>
        </Flex>
    )
}
