import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'email' | 'password' | 'firstName' | 'lastName';

export const rulesProfileForm: RulesForFieldsType<ValidationKeys> = {
  email: {
    userFriendlyFieldName: 'Пошта',
    ruleType: RuleTypes.email,
    required: false,
  },
  password: {
    userFriendlyFieldName: 'Пароль',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 6,
    maxLength: 32,
  },
  firstName: {
    userFriendlyFieldName: 'Імʼя',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 2,
  },
  lastName: {
    userFriendlyFieldName: 'Прізвище',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 2,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  email: { errorMessage: '' },
  password: { errorMessage: '' },
  firstName: { errorMessage: '' },
  lastName: { errorMessage: '' },
};
