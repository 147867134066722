import { emptySplitApi } from '../../api/api';
import {GetAccessZonesResponse} from "./types";

export const scanInfoAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getMyAccessZones: builder.query<GetAccessZonesResponse, { date: string }>({
            query(params) {
                return {
                    url: '/myAccessZones',
                    params,
                };
            },
            providesTags: ['AccessZonesInfo'],
        }),
    }),
});

export const {
    useGetMyAccessZonesQuery,
} = scanInfoAPI;
