import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {Services} from "../../types/services";
import {content} from "../../helpers/content";
import {useNavigate} from "react-router-dom";

export default function RelaxList() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Релакс</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    heroImage={content[Services.SUN_LOUNGERS].heroImage}
                    galleryImages={content[Services.SUN_LOUNGERS].galleryImages}
                    description={content[Services.SUN_LOUNGERS].description}
                    name="Шезлонги на пляжі"
                    callToAction="Обрати"
                    onClickCTA={() => navigate('/relax-list/loungers')}
                    showPrices={false}
                />
                <EntertainmentEntry
                    heroImage={content[Services.MINIPOOL].heroImage}
                    galleryImages={content[Services.MINIPOOL].galleryImages}
                    description={content[Services.MINIPOOL].description}
                    name="Шезлонги на пляжі"
                    service={Services.MINIPOOL}
                    priceDescriptionBold="Вхідний квиток необхідно забронювати окремо у розділі «Білети»."
                />
                <EntertainmentEntry
                    priceDescription="Тільки для проживаючих у A-Home"
                    heroImage={content[Services.BATH_HOUSE_AND_SAUNA].heroImage}
                    galleryImages={content[Services.BATH_HOUSE_AND_SAUNA].galleryImages}
                    description={content[Services.BATH_HOUSE_AND_SAUNA].description}
                    service={Services.BATH_HOUSE_AND_SAUNA}
                    onClickCTA={() => navigate('/accommodation/ahome')}
                    callToAction="до A-Home"
                />
                {/*<EntertainmentEntry*/}
                {/*    disabledDescription="Бронювання разом з проживанням в A-Home"*/}
                {/*    heroImage={content[Services.FLOATING_BATH].heroImage}*/}
                {/*    galleryImages={content[Services.FLOATING_BATH].galleryImages}*/}
                {/*    description={content[Services.FLOATING_BATH].description}*/}
                {/*    service={Services.FLOATING_BATH}*/}
                {/*/>*/}
            </Flex>
        </Flex>
    )
}
