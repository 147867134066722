
// export enum ReservationStatus {
//     Cancelled,
//     Payed,
//     InReview,
//     ReadyForPayment,
//     Pending,
//     Error,
// }

export enum ReservationStatusString {
    Cancelled = 'Cancelled',
    Payed = 'Payed',
    InReview = 'InReview',
    ReadyForPayment = 'ReadyForPayment',
    Pending = 'Pending',
    Error = 'Error',
}

interface GetLabelsReturnType {
    // copied from radix
    color: "gray" | "gold" | "bronze" | "brown" | "yellow" | "amber" | "orange" | "tomato" | "red" | "ruby" | "crimson" | "pink" | "plum" | "purple" | "violet" | "iris" | "indigo" | "blue" | "cyan" | "teal" | "jade" | "green" | "grass" | "lime" | "mint" | "sky" | undefined;
    label: string,
    tooltip: string,
}

// export function getNumberByReservationStatus(status: ReservationStatus | null) {
//     if (status === null) return null;
//
//     switch (status) {
//         case ReservationStatus.ReadyForPayment: return ReservationStatusString.ReadyForPayment;
//         case ReservationStatus.InReview: return ReservationStatusString.InReview;
//         case ReservationStatus.Payed: return ReservationStatusString.Payed;
//         case ReservationStatus.Cancelled: return ReservationStatusString.Cancelled;
//         case ReservationStatus.Pending: return ReservationStatusString.Pending;
//         case ReservationStatus.Error: return ReservationStatusString.Error;
//
//         default: return null;
//     }
// }

export function getLabelsByReservationStatus(status: ReservationStatusString | null): GetLabelsReturnType {
    const defaultLabels: GetLabelsReturnType = {
        label: "Невідомий статус",
        color: "gray",
        tooltip: "Невідомий статус",
    }

    if (status === null) return defaultLabels;

    switch (status) {
        case ReservationStatusString.ReadyForPayment: return {
            label: "Готово до оплати",
            color: "indigo",
            tooltip: "Ви можете сплатити протягом 20хв.",
        };
        case ReservationStatusString.InReview: return {
            label: "На перевірці менеджером",
            color: "orange",
            tooltip: "Менеджер перевіряє вашу заявку та звʼяжеться з вами для уточнення деталей",
        };
        case ReservationStatusString.Payed: return {
            label: "Оплачено",
            color: "teal",
            tooltip: "Усе сплачено!",
        };
        case ReservationStatusString.Cancelled: return {
            label: "Відхилено",
            color: "red",
            tooltip: "Менеджер або ви особисто скасували заявку.",
        };
        case ReservationStatusString.Error: return {
            label: "Помилка",
            color: "crimson",
            tooltip: "Помилка обробки платежу.",
        };
        case ReservationStatusString.Pending: return {
            label: "Платіж отримано",
            color: "yellow",
            tooltip: "Очікуємо підтвердження платіжної системи",
        };

        default: return defaultLabels;
    }
}
