export enum Services {
    ENTRY_TICKET = 1, // parking
    HORSE_RIDE,
    WAKEBOARDING,
    FISHING,

    BATH_HOUSE, // чани, тільки для перегляду
    SUN_LOUNGERS,
    MINIPOOL,
    SAUNA, // тільки для перегляду

    GLAMPING_STANDARD,
    GLAMPING_COMFORT,
    CAMPING_STANDARD,
    CAMPING_COMFORT,
    HOTEL_THERMAL_LAKE,
    HOTEL_THERMAL_FOREST,
    AHOME_THREE_STANDARD,
    AHOME_THREE_COMFORT,
    AHOME_SIX_STANDARD,
    AHOME_SIX_COMFORT,

    BATH_HOUSE_AND_SAUNA, // тільки для перегляду
    FLOATING_BATH, // зараз не буде плавучих чанів, на майбутнє

    SUN_LOUNGERS_WITH_TABLE, // також маємо налаштовувати через адмінку

    // Сапи, каяки, катамарани, човни
    SAP,
    KAYAK,
    CATAMARAN,
    BOAT, // човни

    SAP_KAYAK_CATAMARAN_BOAT_SUBSCRIPTION,
    HORSE_RIDE_SUBSCRIPTION,
    FISHING_SUBSCRIPTION,
    MINIPOOL_SUBSCRIPTION,
    WAKEBOARDING_SUBSCRIPTION,
    PLACEHOLDER_SERVICE_31, // not used
    GLAMPING_STANDARD_BIG,
    GUEST_TICKET_ZONES_1,
    GUEST_TICKET_ZONES_1_2,
    GUEST_TICKET_ZONES_1_2_3,
}

export enum ServiceType {
    ACCOMMODATION = 1,
    SERVICE,
    SUBSCRIPTION,
}

export function getServiceNameByEnum(service: Services | null) {

    if (service === null) return '';

    switch (service) {
        case Services.ENTRY_TICKET: return 'Вхідний білет';
        case Services.HORSE_RIDE: return 'Кінна прогулянка';
        case Services.WAKEBOARDING: return 'Wake Park';
        case Services.FISHING: return 'Риболовля';
        case Services.BATH_HOUSE: return 'Чани';
        case Services.SUN_LOUNGERS: return 'Шезлонги';
        case Services.MINIPOOL: return 'Басейн';
        case Services.SAUNA: return 'Сауна';

        case Services.GLAMPING_STANDARD: return 'Глемпінг: стандарт';
        case Services.GLAMPING_COMFORT: return 'Глемпінг: комфорт';
        case Services.CAMPING_STANDARD: return 'Кемпінг стандарт - Посеред гаю';
        case Services.CAMPING_COMFORT: return 'Кемпінг комфорт - На березі озера';
        case Services.HOTEL_THERMAL_LAKE: return 'Lake Hotel з видом на озеро';
        case Services.HOTEL_THERMAL_FOREST: return 'Lake Hotel з видом на ліс';
        case Services.AHOME_THREE_STANDARD: return 'A-Home: Стандарт 2-місний';
        case Services.AHOME_THREE_COMFORT: return 'A-Home: Комфорт 2 місний (сауна + чан)';
        case Services.AHOME_SIX_STANDARD: return 'A-Home: Стандарт від 5 чоловік';
        case Services.AHOME_SIX_COMFORT: return 'A-Home: Комфорт від 5 людей (сауна + чан)';
        case Services.BATH_HOUSE_AND_SAUNA: return 'Чани і сауна';
        case Services.FLOATING_BATH: return 'Плавучий чан';
        case Services.SUN_LOUNGERS_WITH_TABLE: return 'Шезлонги зі столиком';
        case Services.SAP: return 'Сапи';
        case Services.KAYAK: return 'Каяки';
        case Services.CATAMARAN: return 'Катамарани';
        case Services.BOAT: return 'Човни';
        case Services.SAP_KAYAK_CATAMARAN_BOAT_SUBSCRIPTION: return 'Сапи, каяки, катамарани: абонемент';
        case Services.HORSE_RIDE_SUBSCRIPTION: return 'Кінна прогулянка: абонемент';
        case Services.FISHING_SUBSCRIPTION: return 'Риболовля: абонемент';
        case Services.MINIPOOL_SUBSCRIPTION: return 'Басейн: абонемент';
        case Services.WAKEBOARDING_SUBSCRIPTION: return 'Wake Park: абонемент';
        case Services.PLACEHOLDER_SERVICE_31: return 'Плейсхолдер (не є послугою)';
        case Services.GLAMPING_STANDARD_BIG: return 'Глемпінг: стандарт плюс';
        case Services.GUEST_TICKET_ZONES_1: return 'Гостьовий білет у містечко';
        case Services.GUEST_TICKET_ZONES_1_2: return 'Гостьовий білет – Зона пляжу';
        case Services.GUEST_TICKET_ZONES_1_2_3: return 'Гостьовий білет – Зона готелів';

        default: return '';
    }
}

export function getQuantityLabelByServiceEnum(service: Services | null) {

    if (service === null) return '';

    switch (service) {
        case Services.ENTRY_TICKET: return 'білетів';
        case Services.HORSE_RIDE: return 'годин';
        case Services.WAKEBOARDING: return 'сетів';
        case Services.FISHING: return 'місць';
        case Services.BATH_HOUSE: return '';
        case Services.SUN_LOUNGERS: return 'місць';
        case Services.MINIPOOL: return 'місць';
        case Services.SAUNA: return 'місць';

        case Services.GLAMPING_STANDARD: return 'номерів';
        case Services.GLAMPING_COMFORT: return 'номерів';
        case Services.CAMPING_STANDARD: return 'палаток';
        case Services.CAMPING_COMFORT: return 'палаток';
        case Services.HOTEL_THERMAL_LAKE: return 'номерів';
        case Services.HOTEL_THERMAL_FOREST: return 'номерів';
        case Services.AHOME_THREE_STANDARD: return 'номерів';
        case Services.AHOME_THREE_COMFORT: return 'номерів';
        case Services.AHOME_SIX_STANDARD: return 'котеджів';
        case Services.AHOME_SIX_COMFORT: return 'котеджів';
        case Services.BATH_HOUSE_AND_SAUNA: return '';
        case Services.FLOATING_BATH: return '';
        case Services.SUN_LOUNGERS_WITH_TABLE: return 'місць';
        case Services.SAP: return 'годин';
        case Services.KAYAK: return 'годин';
        case Services.CATAMARAN: return 'годин';
        case Services.BOAT: return 'годин';
        case Services.SAP_KAYAK_CATAMARAN_BOAT_SUBSCRIPTION: return 'шт';
        case Services.HORSE_RIDE_SUBSCRIPTION: return 'шт';
        case Services.FISHING_SUBSCRIPTION: return 'шт';
        case Services.MINIPOOL_SUBSCRIPTION: return 'шт';
        case Services.WAKEBOARDING_SUBSCRIPTION: return 'шт';
        case Services.PLACEHOLDER_SERVICE_31: return '';
        case Services.GLAMPING_STANDARD_BIG: return 'номерів';
        case Services.GUEST_TICKET_ZONES_1: return 'білетів';
        case Services.GUEST_TICKET_ZONES_1_2: return 'білетів';
        case Services.GUEST_TICKET_ZONES_1_2_3: return 'білетів';

        default: return '';
    }
}

export function getManagerPhoneByService(service: Services | null) {

    if (service === null) return null;

    switch (service) {
        case Services.HORSE_RIDE: return {
            phone: '+380663062444',
            formattedPhone: '+380(66)306-24-44',
        };
        case Services.WAKEBOARDING: return {
            phone: '+380968734040',
            formattedPhone: '+380(96)873-40-40',
        };
        case Services.MINIPOOL: return {
            phone: '+380968734040',
            formattedPhone: '+380(96)873-40-40',
        };
        case Services.GLAMPING_STANDARD: return {
            phone: '+380971581577',
            formattedPhone: '+380(97)158-15-77',
        };
        case Services.GLAMPING_COMFORT: return {
            phone: '+380971581577',
            formattedPhone: '+380(97)158-15-77',
        };
        case Services.CAMPING_STANDARD: return {
            phone: '+380977188888',
            formattedPhone: '+380(97)718-88-88',
        };
        case Services.CAMPING_COMFORT: return {
            phone: '+380977188888',
            formattedPhone: '+380(97)718-88-88',
        };
        case Services.AHOME_THREE_STANDARD: return {
            phone: '+380675023405',
            formattedPhone: '+380(67)502-34-05',
        };
        case Services.AHOME_THREE_COMFORT: return {
            phone: '+380675023405',
            formattedPhone: '+380(67)502-34-05',
        };
        case Services.AHOME_SIX_STANDARD: return {
            phone: '+380675023405',
            formattedPhone: '+380(67)502-34-05',
        };
        case Services.AHOME_SIX_COMFORT: return {
            phone: '+380675023405',
            formattedPhone: '+380(67)502-34-05',
        };
        case Services.GLAMPING_STANDARD_BIG: return {
            phone: '+380971581577',
            formattedPhone: '+380(97)158-15-77',
        };

        default: return null;
    }
}

export function getServiceType(service: Services | null) {

    if (service === null) return '';

    switch (service) {
        case Services.ENTRY_TICKET: return ServiceType.SERVICE;
        case Services.HORSE_RIDE: return ServiceType.SERVICE;
        case Services.WAKEBOARDING: return ServiceType.SERVICE;
        case Services.FISHING: return ServiceType.SERVICE;
        case Services.BATH_HOUSE: return ServiceType.SERVICE;
        case Services.SUN_LOUNGERS: return ServiceType.SERVICE;
        case Services.MINIPOOL: return ServiceType.SERVICE;
        case Services.SAUNA: return ServiceType.SERVICE;

        case Services.GLAMPING_STANDARD: return ServiceType.ACCOMMODATION;
        case Services.GLAMPING_COMFORT: return ServiceType.ACCOMMODATION;
        case Services.CAMPING_STANDARD: return ServiceType.ACCOMMODATION;
        case Services.CAMPING_COMFORT: return ServiceType.ACCOMMODATION;
        case Services.HOTEL_THERMAL_LAKE: return ServiceType.ACCOMMODATION;
        case Services.HOTEL_THERMAL_FOREST: return ServiceType.ACCOMMODATION;
        case Services.AHOME_THREE_STANDARD: return ServiceType.ACCOMMODATION;
        case Services.AHOME_THREE_COMFORT: return ServiceType.ACCOMMODATION;
        case Services.AHOME_SIX_STANDARD: return ServiceType.ACCOMMODATION;
        case Services.AHOME_SIX_COMFORT: return ServiceType.ACCOMMODATION;

        case Services.BATH_HOUSE_AND_SAUNA: return ServiceType.SERVICE;
        case Services.FLOATING_BATH: return ServiceType.SERVICE;
        case Services.SUN_LOUNGERS_WITH_TABLE: return ServiceType.SERVICE;
        case Services.SAP: return ServiceType.SERVICE;
        case Services.KAYAK: return ServiceType.SERVICE;
        case Services.CATAMARAN: return ServiceType.SERVICE;
        case Services.BOAT: return ServiceType.SERVICE;
        case Services.SAP_KAYAK_CATAMARAN_BOAT_SUBSCRIPTION: return ServiceType.SUBSCRIPTION;
        case Services.GLAMPING_STANDARD_BIG: return ServiceType.ACCOMMODATION;

        default: return ServiceType.SERVICE;
    }
}
