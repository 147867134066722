import { emptySplitApi } from '../../api/api';
import {GetProfileRequest, GetProfileResponse} from "./types";

export const profileAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // will return empty object for admin login
    getProfile: builder.query<GetProfileResponse, null>({
      query() {
        return {
          url: '/customerProfile',
          method: 'GET',
        };
      },
      providesTags: ['CustomerProfile'],
    }),

    updateProfile: builder.mutation<null, GetProfileRequest>({
      query(data) {
        return {
          url: '/customerProfile',
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: () => ['CustomerProfile'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation
} = profileAPI;
