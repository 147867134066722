import {Services} from "../types/services";
import {STATIC_URL} from "../api/config";

function imagesToGalleryObject(sources: string[]) {
    return sources.map((src) => {
        if (src.includes('.mp4')) {
            return {
                type: "htmlVideo",
                videoSrc: src,
                thumbnail: sources[sources.length -1],
                alt: ""
            }
        }
        return {
            src,
            alt: ''
        }
    })
}

export const content = {
    [Services.HORSE_RIDE]: {
        description: "Відчуйте справжню свободу та гармонію з природою під час кінної прогулянки між лісами та озерами! Ви можете проїхатись верхи або просто прогулятись поряд з конями ✨",
        heroImage: `${STATIC_URL}/images/horseback_riding/HORSE_MAIN_PHOTO.png`,
        durationText: "1 год",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/horseback_riding/HORSE_GALLERY1.png`,
            `${STATIC_URL}/images/horseback_riding/HORSE_GALLERY2-1.png`,
            `${STATIC_URL}/images/horseback_riding/HORSE_GALLERY2.png`,
            `${STATIC_URL}/images/horseback_riding/HORSE_GALLERY3.png`,
            `${STATIC_URL}/images/horseback_riding/horse_on_the_field.jpg`,
            `${STATIC_URL}/images/horseback_riding/woman_jumping_on_the_horse_hero_image.jpg`,
        ])
    },
    [Services.WAKEBOARDING]: {
        description: "Драйв і насолода кожним моментом! У вартість входить спорядження та інструктор. Вейкбординг доступний і новачкам, і дітям. Для профі в нас є два трампліни. Поряд є оглядова тераса та басейн.",
        heroImage: `${STATIC_URL}/images/wake_park/wake_park_hero_image.jpg`,
        durationText: "30 хв",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/wake_park/wake_park_hero_image.jpg`,
            `${STATIC_URL}/images/wake_park/wake_park_video.mp4`,
            `${STATIC_URL}/images/wake_park/rider_backflip_shot.jpg`,
            `${STATIC_URL}/images/wake_park/rider_backside_shot.jpg`,
        ])
    },
    [Services.FISHING]: {
        description: "У LAKE Resort є ідеальне місце для релаксу та хорошого улову. Запрошуємо вас на риболовлю в наших озерах! Максимальна безкоштовна вага вилову — 2.5 кг. Зверніть увагу: спорядження в оренду не надаємо.",
        heroImage: `${STATIC_URL}/images/fishing/three_man_holding_a_fish_hero_image.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/fishing/man_fishing_on_the_pier.jpeg`,
            `${STATIC_URL}/images/fishing/lake_view.jpeg`,
            `${STATIC_URL}/images/fishing/man_holding_a_fish.jpeg`,
            `${STATIC_URL}/images/fishing/three_man_holding_a_fish_hero_image.jpeg`,
        ])
    },
    [Services.SAP]: {
        description: "Ідеально підходить для тих, хто любить активний відпочинок та гармонію з природою. А зустріти світанок чи захід сонця посеред озера — що може бути більш захоплюючим? ",
        heroImage: `${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`,
        durationText: "1 год",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sup_and_kayak/sup_and_kayak_video.mp4`,
            `${STATIC_URL}/images/sup_and_kayak/parked_kayak.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`,
        ])
    },
    [Services.KAYAK]: {
        description: "Насолоджуйтесь веслуванням по озеру, надихаючись мальовничими місцями. Підходить як для катання одному, так і для пар.",
        heroImage: `${STATIC_URL}/images/sup_and_kayak/kayak_hero.png`,
        durationText: "1 год",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sup_and_kayak/sup_and_kayak_video.mp4`,
            `${STATIC_URL}/images/sup_and_kayak/parked_kayak.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/kayak_hero.png`,
        ])
    },
    [Services.CATAMARAN]: {
        description: "Чудовий вибір для сімейного відпочинку або веселих компаній. Легкий, цікавий і безпечний спосіб провести час на воді.",
        heroImage: `${STATIC_URL}/images/sup_and_kayak/catamaran_hero.png`,
        durationText: "1 год",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sup_and_kayak/sup_and_kayak_video.mp4`,
            `${STATIC_URL}/images/sup_and_kayak/parked_kayak.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/catamaran_hero.png`,
        ])
    },
    [Services.BOAT]: {
        description: "Ідеальні для риболовлі або романтичних прогулянок.",
        heroImage: `${STATIC_URL}/images/sup_and_kayak/boat_hero_image.png`,
        durationText: "1 год",
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sup_and_kayak/sup_and_kayak_video.mp4`,
            `${STATIC_URL}/images/sup_and_kayak/parked_kayak.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`,
            `${STATIC_URL}/images/sup_and_kayak/boat_hero_image.png`,
        ])
    },
    [Services.SUN_LOUNGERS]: {
        description: "Релакс та насолода природою на нашому пляжі з комфортними шезлонгами, забезпеченими тінню від сонця та зручними столиками. Відпочивайте, засмагайте або насолоджуйтеся активним відпочинком, завдяки легкому доступу до бару, озера та дитячої зони. Ідеально підходить для кожного віку!",
        heroImage: `${STATIC_URL}/images/sunbeds/sunbeds_hero_image.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sunbeds/sunbeds_hero_image.jpeg`,
            `${STATIC_URL}/images/sunbeds/sunbeds_side_view.jpeg`,
            `${STATIC_URL}/images/sunbeds/sunbeds_upfront_view.jpg`,
        ])
    },
    [Services.SUN_LOUNGERS_WITH_TABLE]: {
        description: "Піддайтеся релаксації та насолоді природою на нашому пляжі з комфортними шезлонгами, забезпеченими тінню від сонця та зручними столиками. Відпочивайте, засмагайте або насолоджуйтеся активним відпочинком, завдяки легкому доступу до бару, озера, басейну та дитячої зони. Ідеально підходить для кожного віку — забронюйте ваш ідеальний день на пляжі зараз!",
        heroImage: `${STATIC_URL}/images/sunbeds/sunbeds_hero_image.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/sunbeds/sunbeds_hero_image.jpeg`,
            `${STATIC_URL}/images/sunbeds/sunbeds_side_view.jpeg`,
            `${STATIC_URL}/images/sunbeds/sunbeds_upfront_view.jpg`,
        ])
    },
    [Services.MINIPOOL]: {
        description: "Ідеальне місце для плавання, релаксу з чудовими краєвидами. У вартість входить:  доступ до басейну, шезлонги біля басейну, плавання в озері.",
        heroImage: `${STATIC_URL}/images/mini_pool/MINIPOOL_MAIN_PHOTO.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/mini_pool/MINIPOOL_GALLERY_1.jpg`,
            `${STATIC_URL}/images/mini_pool/MINIPOOL_GALLERY_2.jpg`,
            `${STATIC_URL}/images/mini_pool/MINIPOOL_GALLERY_3.png`,
            `${STATIC_URL}/images/mini_pool/MINIPOOL_GALLERY_4.png`,
        ])
    },
    [Services.BATH_HOUSE_AND_SAUNA]: {
        description: "Відчуйте унікальний спокій з нашою сауною і чанами! Занурьтесь у розкішні чани та сауну, відновіть здоров'я і душевну рівновагу, насолоджуючись природою.",
        heroImage: `${STATIC_URL}/images/vat_and_sauna/vat_with_campfire_hero_image.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/vat_and_sauna/vat_and_sauna_video.mp4`,
            `${STATIC_URL}/images/vat_and_sauna/vat_at_night.jpg`,
            `${STATIC_URL}/images/vat_and_sauna/glasses_of_wine_on_a_vat_frame.jpg`,
            `${STATIC_URL}/images/vat_and_sauna/vat_with_campfire_hero_image.jpg`, // last - thumb image for video
        ])
    },
    [Services.FLOATING_BATH]: {
        description: "Відчуйте романтику і релаксацію у плавучому чані! Переживіть незабутні моменти удвох, насолоджуючись теплою водою і чарівними краєвидами озера. Забронюйте ваш унікальний відпочинок на воді вже сьогодні і відновіть енергію серед природи!",
        heroImage: `${STATIC_URL}/images/floating_vat/floating_vat_hero_image.png`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/floating_vat/floating_vat_with_flags.png`,
            `${STATIC_URL}/images/floating_vat/two_woman_in_the_floating_vat.png`,
            `${STATIC_URL}/images/floating_vat/floating_vat.png`,
            `${STATIC_URL}/images/floating_vat/floating_vat_in_the_lake.png`,
            `${STATIC_URL}/images/floating_vat/floating_vat_hero_image.png`,
        ])
    },
    [Services.GLAMPING_STANDARD]: {
        description: "Площа: 10 кв.м., розрахованний на 2-х осіб. Всередині: двоспальне ліжко, холодильник, вентилятор, обідній стіл, Wi-Fi. Поряд з глемпом: тераса, мангал, гамак. Душові та санвузол розташовані на території. Зверніть увагу ціна у П'ятницю розраховується як за Вихідний день! ",
        heroImage: `${STATIC_URL}/images/glamping/GLEMPING_STANDART_MAIN_PHOTO.png`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/glamping/GLEMPING_STANDART_GALLERY1.png`,
            `${STATIC_URL}/images/glamping/GLEMPING_STANDART_GALLERY2.png`,
            `${STATIC_URL}/images/glamping/GLEMPING_STANDART_GALLERY3.png`,
        ])
    },
    [Services.GLAMPING_COMFORT]: {
        description: "Площа: 28 кв.м., розрахованний на 2-х осіб. Всередині: двоспальне ліжко, холодильник, кондиціонер, обідній стіл, санвузол та душ, Wi-FI. Поряд з глемпом: тераса, мангал, вид на озеро. Зверніть увагу ціна у П'ятницю розраховується як за Вихідний день! ",
        heroImage: `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_MAIN_PHOTO.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_MAIN_PHOTO.jpg`,
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_GALLERY_1.jpg`,
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_GALLERY_2.jpg`,
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_GALLERY_3.jpg`,
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_GALLERY_4.jpg`,
            `${STATIC_URL}/images/glamping/Comfort/GLEMPING_COMFORT_GALLERY_5.jpg`,
        ])
    },
    [Services.CAMPING_COMFORT]: {
        description: "Забронюйте свою відпустку в кемпінгу «Lake Resort»! Виберіть затишок на березі або в сосновому гаю, відпочиньте з вудкою або біля мангалу, і знайдіть спокій у комфорті, об'єднаному з природою.",
        heroImage: `${STATIC_URL}/images/camping/CAMPING_MAIN_PHOTO.png`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/camping/Comfort/CAMPING_GALLERY_COMFORT_1.png`,
            `${STATIC_URL}/images/camping/Comfort/CAMPING_GALLERY_COMFORT_2.png`,
            `${STATIC_URL}/images/camping/Comfort/CAMPING_GALLERY_COMFORT_3.png`,
        ])
    },
    [Services.CAMPING_STANDARD]: {
        description: "Зона розташована ближче до пляжу, озера та інших розваг. Зручності: душові та санвузол, ватра, паркування, вид на озеро, альтанки. За необхідності надаємо палатки (+400грн до вартості)",
        heroImage: `${STATIC_URL}/images/camping/camping_sunset_view.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/camping/Standart/CAMPING_GALLERY_1.png`,
            `${STATIC_URL}/images/camping/Standart/CAMPING_GALLERY_2.png`,
            `${STATIC_URL}/images/camping/Standart/CAMPING_GALLERY_3.png`,
        ])
    },
    [Services.AHOME_THREE_STANDARD]: {
        description: "Один двоповерховий фрейм на 2-х людей. У фреймі: велике двухспальне ліжко та зручний двохспальний розкладний диван, повноцінна ванна кімната, кухня та мангальна зона з усім необхідним посудом. За кожну людину +500грн (макс.4)",
        heroImage: `${STATIC_URL}/images/a_home/a_home_inner_view_lounge.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/a_home/a_home_window_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_kitchen.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_lounge.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_front_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_hero_image.jpg`,
        ])
    },
    [Services.AHOME_THREE_COMFORT]: {
        description: "Один двоповерховий фрейм на 2-х людей. У фреймі: велике двухспальне ліжко та зручний двохспальний розкладний диван, повноцінна ванна кімната, кухня та мангальна зона з усім необхідним посудом. Додаток: персональна сауна та чан. За кожну людину +500грн (макс.4)",
        heroImage: `${STATIC_URL}/images/a_home/a_home_hero_image.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/a_home/a_home_window_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_kitchen.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_lounge.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_front_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_hero_image.jpg`,
        ])
    },
    [Services.AHOME_SIX_STANDARD]: {
        description: "Два двоповерхові фрейми, розраховані від 5-ти людей. У фреймі: велике двухспальне ліжко та зручний двохспальний розкладний диван, повноцінна ванна кімната, кухня та мангальна зона з усім необхідним посудом. За кожну людину +500грн (макс.8)",
        heroImage: `${STATIC_URL}/images/a_home/a_home_window_view.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/a_home/a_home_window_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_kitchen.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_lounge.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_front_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_hero_image.jpg`,
        ])
    },
    [Services.AHOME_SIX_COMFORT]: {
        description: "Два двоповерхові фрейми, розраховані від 5-ти людей. У фреймі: велике двухспальне ліжко та зручний двохспальний розкладний диван, повноцінна ванна кімната, кухня та мангальна зона з усім необхідним посудом. Додаток: персональна сауна та чан. За кожну людину +500грн (макс.8).",
        heroImage: `${STATIC_URL}/images/a_home/a_home_front_view.jpeg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/a_home/a_home_window_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_kitchen.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_inner_view_lounge.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_front_view.jpeg`,
            `${STATIC_URL}/images/a_home/a_home_hero_image.jpg`,
        ])
    },

    [Services.HOTEL_THERMAL_LAKE]: {
        description: "",
        heroImage: "",
        galleryImages: imagesToGalleryObject([])
    },

    [Services.HOTEL_THERMAL_FOREST]: {
        description: "",
        heroImage: "",
        galleryImages: imagesToGalleryObject([])
    },

    [Services.GLAMPING_STANDARD_BIG]: {
        description: "Ідеальний варіант для комфортного сімейного  відпочинку на природі. Площа глемпу складає 28 кв.м. для розміщення до 4 осіб. Всередині: двоспальне ліжко, диван розкладний, холодильник, кондиціонер, обідній стіл, Wi-Fi. Поряд з глемпом: тераса, мангал, вид на озеро. Зверніть увагу ціна у П'ятницю розраховується як за Вихідний день! ",
        heroImage: `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_MAIN_PHOTO.jpg`,
        galleryImages: imagesToGalleryObject([
            `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_MAIN_PHOTO.jpg`,
            `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_GALLERY_1.jpg`,
            `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_GALLERY_2.jpg`,
            `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_GALLERY_3.jpg`,
            `${STATIC_URL}/images/glamping/StndPLUS/GLEMPING_STD_PLUS_GALLERY_4.jpg`,
        ])
    },


    // [999]: {
    //     description: "",
    //     heroImage: `${STATIC_URL}`,
    //     galleryImages: imagesToGalleryObject([
    //         `${STATIC_URL}`,
    //         `${STATIC_URL}`,
    //         `${STATIC_URL}`,
    //         `${STATIC_URL}`, // last - thumb image for video
    //     ])
    // },
}
