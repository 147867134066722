import { emptySplitApi } from '../../api/api';
import {BookingRequestPayload, FastBookingRequestPayload, FastBookingResponse, GetSpotsResponse} from "./types";
import {MakePaymentResponse} from "../Reservations/types";
import {Services} from "../../types/services";

export const bookingAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        createBooking: builder.mutation<null, BookingRequestPayload>({
            query(data) {
                return {
                    url: '/booking',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['ReservationsList', 'SpotsCalculation', 'AccessZonesInfo'],
        }),
        createFastBooking: builder.mutation<FastBookingResponse, FastBookingRequestPayload>({ // for parking, loungers etc
            query(data) {
                return {
                    url: '/fastBooking',
                    method: 'POST',
                    body: {
                        ...data,
                    },
                };
            },
            invalidatesTags: ['ReservationsList', 'SpotsCalculation', 'AccessZonesInfo'],
        }),

        makePaymentAfterScan: builder.query<MakePaymentResponse, { reservationID: string }>({
            query(params) {
                return {
                    url: `/makePayment/${params.reservationID}`,
                };
            },
        }),

        getSpotsCalculation: builder.query<GetSpotsResponse, { date?: string }>({
            query(params) {
                return {
                    url: '/calcSpotsConfiguration',
                    params,
                };
            },
            providesTags: (result, error, arg) => [
                { type: 'SpotsCalculation', ...arg },
            ],
        }),

        getActivePromoCode: builder.mutation<any, {code: string, serviceID: Services}>({
            query(data) {
                return {
                    url: '/activepromocode',
                    method: 'GET',
                    params: data,
                };
            },
        }),
    }),
});

export const {
    useCreateBookingMutation,
    useCreateFastBookingMutation,
    useMakePaymentAfterScanQuery,
    useGetSpotsCalculationQuery,
    useGetActivePromoCodeMutation,
} = bookingAPI;
