import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {content} from "../../helpers/content";
import {Services} from "../../types/services";

export default function LoungersList() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Шезлонги</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    heroImage={content[Services.SUN_LOUNGERS].heroImage}
                    galleryImages={content[Services.SUN_LOUNGERS].galleryImages}
                    description={content[Services.SUN_LOUNGERS].description}
                    service={Services.SUN_LOUNGERS}
                    priceDescriptionBold="Вхідний квиток необхідно забронювати окремо у розділі «Білети»."
                />
                {/*<EntertainmentEntry*/}
                {/*    heroImage={content[Services.SUN_LOUNGERS_WITH_TABLE].heroImage}*/}
                {/*    galleryImages={content[Services.SUN_LOUNGERS_WITH_TABLE].galleryImages}*/}
                {/*    description={content[Services.SUN_LOUNGERS_WITH_TABLE].description}*/}
                {/*    service={Services.SUN_LOUNGERS_WITH_TABLE}*/}
                {/*    disabledDescription="Вже скоро"*/}
                {/*priceDescription="Бронювання вхідного квитка окремо"*/}
                {/*/>*/}
            </Flex>
        </Flex>
    )
}
