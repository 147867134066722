import { emptySplitApi } from '../../api/api';

export const loginAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation<{accessToken: string, refreshToken: string, exception: null | any}, { phone: string, password: string }>({
            query(data) {

                return {
                    url: `/login`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        recoverPassword: builder.mutation<any, { phone: string }>({
            query(data) {

                return {
                    url: `/forgotpassword`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const {
    useLoginUserMutation,
    useRecoverPasswordMutation,
} = loginAPI;
