import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'phoneNumber';

export const rulesRecoverPasswordForm: RulesForFieldsType<ValidationKeys> = {
  phoneNumber: {
    userFriendlyFieldName: 'Номер телефону',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 13,
    maxLength: 13,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  phoneNumber: { errorMessage: '' },
};
