import React, {useEffect, useRef} from "react";
import {Flex, Heading, Text} from "@radix-ui/themes";
import {useParams} from "react-router-dom";
import {useMakePaymentAfterScanQuery} from "../booking/bookingAPI";

export default function LinkPayment() {
    const { reservationID = '' } = useParams();

    const paymentFormRef = useRef<HTMLFormElement>(null);

    const {data: paymentData } = useMakePaymentAfterScanQuery({ reservationID }, { skip: !reservationID});

    useEffect(() => {
        setTimeout(() => {
            if (! paymentData) return;

            if (! paymentFormRef.current) {
                console.error('No payment form ref');
                return;
            }
            paymentFormRef.current.submit();
        }, 150); // todo: find better way instead of timeout
    }, [paymentData]);

    return (
        <Flex direction='column' pt='4'>
            <Heading size="4">Перенаправлення на оплату...</Heading>

            <div style={{display: 'none'}}>
                <form ref={paymentFormRef} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                    <input type="hidden" name="data" value={paymentData?.data || ''}/>
                    <input type="hidden" name="signature" value={paymentData?.signature || ''}/>
                </form>
            </div>
        </Flex>
    );
}
