import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "./EntertainmentEntry";
import {content} from "../../helpers/content";
import {Services} from "../../types/services";

export default function SapKayakCatamaranList() {

    return (
        <Flex direction='column' pt='4'>
            <Heading>Сапи, каяки, катамарани</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    disabledDescription="Бронювання на місці"
                    priceDescription="Бронювання сапів на добу: 1шт - 500 грн; 2 сапи - 800 грн"
                    durationText={content[Services.SAP].durationText}
                    heroImage={content[Services.SAP].heroImage}
                    galleryImages={content[Services.SAP].galleryImages}
                    description={content[Services.SAP].description}
                    service={Services.SAP}
                />
                <EntertainmentEntry
                    disabledDescription="Бронювання на місці"
                    durationText={content[Services.KAYAK].durationText}
                    heroImage={content[Services.KAYAK].heroImage}
                    galleryImages={content[Services.KAYAK].galleryImages}
                    description={content[Services.KAYAK].description}
                    service={Services.KAYAK}
                />
                <EntertainmentEntry
                    disabledDescription="Бронювання на місці"
                    durationText={content[Services.CATAMARAN].durationText}
                    heroImage={content[Services.CATAMARAN].heroImage}
                    galleryImages={content[Services.CATAMARAN].galleryImages}
                    description={content[Services.CATAMARAN].description}
                    service={Services.CATAMARAN}
                />
                <EntertainmentEntry
                    disabledDescription="Бронювання на місці"
                    durationText={content[Services.BOAT].durationText}
                    heroImage={content[Services.BOAT].heroImage}
                    galleryImages={content[Services.BOAT].galleryImages}
                    description={content[Services.BOAT].description}
                    service={Services.BOAT}
                />
            </Flex>
        </Flex>
    );
}

