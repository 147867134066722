import React, {useEffect, useState} from "react";
import {Box, Button, Card, Flex, Heading, Text, TextField} from "@radix-ui/themes";
import {useMask} from "@react-input/mask";
import {useFormValidation} from "../../helpers/validation";
import {formatPhoneAfterMask, getPhoneNumberFromStore, savePhoneNumberToStore} from "../../helpers/utils";
import {rulesRecoverPasswordForm, validationErrorState, ValidationKeys} from "./recoverPasswordValidationRules";
import {useRecoverPasswordMutation} from "./loginAPI";
import {maskConfig} from "../../helpers/mask";
import SuccessPasswordRecovery from "./SuccessPasswordRecovery";

export default function ForgotPassword() {
    const inputRef = useMask(maskConfig);

    const [phoneNumber, setPhoneNumber] = useState('+380');
    const [showForm, setShowForm] = useState(true);

    const [recoverPasswordRequest, { isLoading, isError }] = useRecoverPasswordMutation();

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        rulesRecoverPasswordForm,
        validationErrorState,
        {
            phoneNumber: formatPhoneAfterMask(phoneNumber),
        },
    );

    useEffect(() => {
        (async () => {
            const number = await getPhoneNumberFromStore();
            if (number) {
                setPhoneNumber(number);
            }
        })()
    }, []);

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        const response = await recoverPasswordRequest({
            phone: formatPhoneAfterMask(phoneNumber),
        });

        // @ts-ignore
        if (response && (! response.error)) {
            await savePhoneNumberToStore(phoneNumber);
            setShowForm(false);
        }
    };

    return (
        <Flex direction='column' pt='4'>
            <Heading>Відновити пароль</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    {
                        showForm ? (
                            <Card size="2" variant="classic" className="dashboard-feature__card">
                                <Flex direction='column' gap='4'>
                                    <label>
                                        <Text as="div" size="2" mb="1" weight="bold">
                                            Номер телефону
                                        </Text>
                                        <TextField.Root
                                            type="tel"
                                            ref={inputRef}
                                            value={phoneNumber}
                                            placeholder="Ваш номер"
                                            onChange={(e) => {
                                                setPhoneNumber(e.target.value)
                                                onValidationInputChange('phoneNumber');
                                            }}
                                        />
                                        <Text size="1" color="crimson">{validationState.phoneNumber.errorMessage}</Text>
                                    </label>
                                </Flex>
                                <Flex mt="4" justify="end" align="center">
                                    <Button
                                        onClick={onSubmit}
                                        disabled={isLoading}
                                    >
                                        Відправити СМС
                                    </Button>
                                </Flex>
                                <Flex justify="end" mt="2">
                                    {
                                        isError && <Text size="1" color="crimson">Номер відсутній у системі або сталася помилка</Text>
                                    }
                                </Flex>
                            </Card>
                        ) : <SuccessPasswordRecovery />
                    }
                </Box>
            </Flex>
        </Flex>
    )
}
