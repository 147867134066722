import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Services} from "../../types/services";

interface INITIAL_STATE {
    showBookingModal: boolean,
    isHotelBooking: boolean,
    numberOfSpots: number, // кількість
    selectedService: Services | null,
    date: string,
    endDate: string,
    promoCode: string,
}
const initialState: INITIAL_STATE = {
    showBookingModal: false,
    isHotelBooking: false,
    numberOfSpots: 1,
    selectedService: null,
    date: '',
    endDate: '',
    promoCode: '',
}

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        resetForm: (state) => {
            state.showBookingModal = initialState.showBookingModal;
            state.isHotelBooking = initialState.isHotelBooking;
            state.selectedService = initialState.selectedService;
            state.endDate = initialState.endDate;
            state.promoCode = initialState.promoCode;
        },
        showBookingModal: (state, action: PayloadAction<boolean>) => {
            state.showBookingModal = action.payload
        },
        setIsHotelBooking: (state, action: PayloadAction<boolean>) => {
            state.isHotelBooking = action.payload
        },
        setService: (state, action: PayloadAction<Services>) => {
            state.selectedService = action.payload
        },
        setBookingDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload
        },
        setBookingEndDate: (state, action: PayloadAction<string>) => {
            state.endDate = action.payload
        },
        setNumberOfSpots: (state, action: PayloadAction<number>) => {
            state.numberOfSpots = action.payload
        },
        setPromoCode: (state, action: PayloadAction<string>) => {
            state.promoCode = action.payload
        },
    }
})

export const {
    resetForm,
    setIsHotelBooking,
    showBookingModal,
    setBookingDate,
    setBookingEndDate,
    setService,
    setNumberOfSpots,
    setPromoCode,
} = bookingSlice.actions;

export default bookingSlice;
