import React, {useState} from "react";
import {Box, Button, Card, Flex, Grid, Inset, Skeleton, Text} from "@radix-ui/themes";
import {useAppDispatch} from "../../store/hooks";
import {setIsHotelBooking, setService, showBookingModal} from "../booking/bookingSlice";
import {getServiceNameByEnum, Services} from "../../types/services";
import {SlideshowLightbox} from "lightbox.js-react";
import {getPriceForCurrentMonth} from "../../helpers/prices";
import {getMonthDeclination} from "../../helpers/utils";

interface EntertainmentEntryProps {
    heroImage: string,
    galleryImages?: object[],
    name?: string,
    description: string,
    disabledDescription?: string,
    service?: Services,
    callToAction?: string,
    onClickCTA?: () => void,
    isHotelBooking?: boolean,
    showPrices?: boolean,
    priceDescription?: string,
    priceDescriptionBold?: string,
    durationText?: string,
}

// todo: extract to shared components and rename
export default function EntertainmentEntry(props: EntertainmentEntryProps) {

    const {
        heroImage = '',
        galleryImages = [],
        name = '',
        description,
        disabledDescription,
        service,
        callToAction = 'Забронювати',
        onClickCTA,
        isHotelBooking,
        showPrices = true,
        priceDescription,
        priceDescriptionBold,
        durationText,
    } = props;

    let [isOpen, setIsOpen] = useState(false);
    let [isImageLoaded, setIsImageLoaded] = useState(false);
    const dispatch = useAppDispatch();

    const callToActionClick = () => {

        if (onClickCTA) {
            onClickCTA();
            return;
        }

        dispatch(showBookingModal(true));
        dispatch(setIsHotelBooking(Boolean(isHotelBooking)));

        if (service) { dispatch(setService(service)) }
    }

    const prices = getPriceForCurrentMonth(service);

    return (
        <Box>
            <Card size="2" variant="classic" className="dashboard-feature__card">
                <Inset clip="padding-box" side="top" pb="current">
                    <Skeleton loading={ ! isImageLoaded} width='100%' height='200px' />
                    <img
                        onClick={() => setIsOpen(true)}
                        src={heroImage}
                        alt="Фото"
                        onLoad={() => setIsImageLoaded(true)}
                        style={{
                            display: isImageLoaded ? 'block' : "none",
                            objectFit: 'cover',
                            width: '100%',
                            height: 'auto',
                            maxHeight: '200px',
                            backgroundColor: 'var(--gray-5)',
                        }}
                    />
                </Inset>
                <Text as="div" size="4" weight="bold">
                    { service !== undefined ? getServiceNameByEnum(service) : name }
                </Text>
                <Text as="div" size="1" color="gray">
                    { description }
                </Text>
                <Box mt='6'>
                    {
                        priceDescription && (
                            <Box mb="2">
                                <Text size="1" style={{display: "block", fontStyle: 'italic', lineHeight: 1.4}}>
                                    {priceDescription}
                                </Text>
                            </Box>
                        )
                    }
                    {
                        priceDescriptionBold ? (
                            <Box mb="2">
                                <Text size="1" weight="bold" style={{display: "block", fontStyle: 'italic', lineHeight: 1.4}}>
                                    {priceDescriptionBold}
                                </Text>
                            </Box>
                        ) : null
                    }
                    <Flex justify='between' align='end' gapY="2" wrap="wrap">
                        <Flex direction='column'>
                            {
                                showPrices && (
                                    <>
                                        {
                                            prices.workDaySalePercentage ? (
                                                <>
                                                    <Flex direction="column" gap="1" mt="2">
                                                        <Text size="2" weight="bold">Ціна: {prices.weekdayPrice}грн</Text>
                                                        <Text size='1'>у будні (пн-пт) знижка <b>-{prices.workDaySalePercentage}%</b></Text>
                                                    </Flex>
                                                </>
                                            ) : (
                                                <>
                                                    <Text size="1" style={{fontStyle: 'italic'}}>ціни у {getMonthDeclination(prices.monthNameLocale)}</Text>
                                                    <Grid columns="2" gapX="2" gapY="1" style={{ gridTemplateColumns: "auto auto" }}>
                                                        <Text size='2'>
                                                            {prices.fridayAsWeekendPrice ? 'ПТ-НД:' : 'вихідні:'}
                                                        </Text>
                                                        <Text size='2' weight="bold">
                                                            {prices.weekdayPrice}грн
                                                            {durationText ? ` / ${durationText}` : ''}
                                                        </Text>
                                                        <Text size='2'>
                                                            {prices.fridayAsWeekendPrice ? 'ПН-ЧТ:' : 'будні:'}
                                                        </Text>
                                                        <Text size='2' weight="bold">
                                                            {prices.workDayPrice}грн
                                                            {durationText ? ` / ${durationText}` : ''}
                                                        </Text>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Flex>
                        <Flex direction='column'>
                            <Button
                                size='2'
                                disabled={Boolean(disabledDescription)}
                                onClick={callToActionClick}
                            >
                                {disabledDescription ?? callToAction}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Card>
            {
                galleryImages.length > 0 && (
                    <SlideshowLightbox
                        animateThumbnails={false}
                        imgAnimation="none"
                        images={galleryImages}
                        showThumbnails={false}
                        open={isOpen}
                        lightboxIdentifier={`lightbox-${service}`}
                        onClose={() =>{setIsOpen(false)}}>
                    </SlideshowLightbox>
                )
            }
        </Box>
    )
}
