import React from "react";
import {Box, Button, Callout, Flex, Heading, Spinner, Text} from "@radix-ui/themes";
import ReservationsEntry from "./ReservationsEntry";
import {useGetReservationsQuery} from "./reservationsAPI";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import ReservationsFilters from "./ReservationsFilters";
import {setPage} from "./reservationsSlice";
import Pagination from "../../shared/Pagination";
import CancelReservationModal from "./CancelReservationModal";
import {InformationCircleIcon} from "@heroicons/react/24/outline";

export default function ReservationsList() {

    const {
        page,
        perPage,
        status,
    } = useAppSelector((state: RootState) => state.reservations);

    const dispatch = useAppDispatch();

    const {
        data: reservationsList,
        isLoading,
        isFetching
    } = useGetReservationsQuery({
        pageNumber: page,
        pageSize: perPage,
        status,
    }, { pollingInterval: 60 * 1000}); // every min

    return (
        <Flex direction='column' pt='4'>
            <Heading>Ваші резервації</Heading>

            <Callout.Root my="2" size='1' style={{ alignItems: 'center' }}>
                <Callout.Icon>
                    <InformationCircleIcon width="16" height="16" />
                </Callout.Icon>
                <Callout.Text size='1'>
                    Шановні гості, після отримання вашим резервом статусу “Готово до оплати”, у вас є 30 хвилин на те,
                    щоб оплатити замовлення. В іншому випадку не оплачений резерв буде відмінений.
                </Callout.Text>
            </Callout.Root>

            <Box>
                <ReservationsFilters />
            </Box>

            <Flex mt='4' direction='column' gap='4'>

                {
                    (isLoading) && <Flex justify="center"><Spinner size="3" /> </Flex>
                }

                {
                    reservationsList?.data.length === 0 && (
                        <Flex justify="center" mt="4">
                            <Text size="4" weight="bold" color="gray">Немає записів</Text>
                        </Flex>
                    )
                }

                {
                    reservationsList?.data.map((reservation) => (
                        <ReservationsEntry
                            key={reservation.id}
                            reservation={reservation}
                        />
                    ))
                }

                {
                    reservationsList?.data && (
                        <Pagination
                            perPage={perPage}
                            page={page}
                            totalPages={reservationsList?.totalPages}
                            total={reservationsList.total}

                            setPage={(page) => dispatch(setPage(page))}
                            isLoading={isLoading || isFetching}
                        />
                    )
                }

            </Flex>

            <CancelReservationModal />
        </Flex>
    )
}
