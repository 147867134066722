import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {Services} from "../../types/services";
import {content} from "../../helpers/content";

export default function CampingList() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Кемпінг</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.CAMPING_STANDARD].heroImage}
                    galleryImages={content[Services.CAMPING_STANDARD].galleryImages}
                    description={content[Services.CAMPING_STANDARD].description}
                    service={Services.CAMPING_STANDARD}
                />
                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.CAMPING_COMFORT].heroImage}
                    galleryImages={content[Services.CAMPING_COMFORT].galleryImages}
                    description={content[Services.CAMPING_COMFORT].description}
                    service={Services.CAMPING_COMFORT}
                />
            </Flex>
        </Flex>
    )
}
