import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'phoneNumber' | 'password';

export const rulesLoginForm: RulesForFieldsType<ValidationKeys> = {
  phoneNumber: {
    userFriendlyFieldName: 'Номер телефону',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 13,
    maxLength: 13,
  },
  password: {
    userFriendlyFieldName: 'Пароль',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 32,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  phoneNumber: { errorMessage: '' },
  password: { errorMessage: '' },
};
