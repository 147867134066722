import {AuthState} from "../features/Login/authSlice";
import {Services} from "../types/services";
import {TimeoutId} from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

export function todayDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

export function formatPhoneAfterMask(phone: string) {
    return phone.trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll(')', '')
        .replaceAll('(', '')
        // clear mask for validation
        .replaceAll('_', '')
        .replaceAll('-', '')
}

async function getValueFromStorage(key: string) {
    return localStorage.getItem(key);
}

async function saveToStorage(key: string, value: string) {
    return localStorage.setItem(key, value);
}

const STORAGE_AUTH_SESSION = 'auth_session';
const STORAGE_PHONE_NUMBER = 'store_phone_number';
export async function getAuthStore() {
    const authenticationStore = await getValueFromStorage(STORAGE_AUTH_SESSION);

    if (!authenticationStore) {
        return null;
    }

    return JSON.parse(authenticationStore);
}

export async function saveAuthStore(authenticationStore: AuthState) {
    await saveToStorage(STORAGE_AUTH_SESSION, JSON.stringify(authenticationStore));
}

export async function getPhoneNumberFromStore() {
    const store = await getValueFromStorage(STORAGE_PHONE_NUMBER);

    return store;
}

export async function savePhoneNumberToStore(number: string) {
    await saveToStorage(STORAGE_PHONE_NUMBER, number);
}

export function getMonthDeclination(month: string) {
    if (! month) return '';

    switch (month) {
        case 'січень': return 'січні';
        case 'лютий': return 'лютому';
        case 'березень': return 'березні';
        case 'квітень': return 'квітні';
        case 'травень': return 'травні';
        case 'червень': return 'червні';
        case 'липень': return 'липні';
        case 'серпень': return 'серпні';
        case 'вересень': return 'вересні';
        case 'жовтень': return 'жовтні';
        case 'листопад': return 'листопаді';
        case 'грудень': return 'грудні';

        default: return month;
    }
}

export function debounce(mainFunction: (...args: any[]) => any, delay: number) {
    // Declare a variable called 'timer' to store the timer ID
    let timer: TimeoutId;

    // Return an anonymous function that takes in any number of arguments
    return function (...args: any[]) {
        // Clear the previous timer to prevent the execution of 'mainFunction'
        clearTimeout(timer);

        // Set a new timer that will execute 'mainFunction' after the specified delay
        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};
