export const iframeText = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	font-size:12.0pt;
	font-family:"Cambria",serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Cambria",serif;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Cambria",serif;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:32.8pt 47.15pt 24.0pt 47.45pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-family:"Times New Roman",serif'>ДОГОВІР</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=UK
style='font-family:"Times New Roman",serif'>ПУБЛІЧНОЇ ОФЕРТИ </span></b></p>

<p class=MsoNormal style='margin-left:7.1pt'><span lang=UK style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-.35pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>Фізична особа – підприємець Клешко Віолетта Сергіївна,</span><span
lang=UK style='font-family:"Times New Roman",serif'> (далі – </span><b><span
lang=UK style='font-family:"Times New Roman",serif;color:black;background:white'>«</span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>Виконавець</span></b><b><span
lang=UK style='font-family:"Times New Roman",serif;color:black;background:white'>»</span></b><span
lang=UK style='font-family:"Times New Roman",serif'>) керуючись ст. ст 633, 634
Цивільного кодексу України, пропонує необмеженому колу фізичних осіб укласти
цей договір на наступних умовах. </span></p>

<p class=MsoNormal style='margin-right:-.35pt'><b><span lang=UK
style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ВИЗНАЧЕННЯ ТЕРМІНІВ</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-right:-.35pt'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.95pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>Публічна<span style='letter-spacing:.05pt'> </span>оферта<span
style='letter-spacing:.05pt'> </span>–<span style='letter-spacing:.05pt'> </span>пропозиція<span
style='letter-spacing:.05pt'> </span>Виконавця<span style='letter-spacing:.05pt'>
</span>(розміщена у відкритому доступі в мережі Інтернет на<span
style='letter-spacing:.05pt'> </span>веб-сайті<span style='letter-spacing:.05pt'>
</span>Виконавця), адресована необмеженому колу фізичних та юридичних осіб
відповідно до статті 641<span style='letter-spacing:-2.8pt'> </span>Цивільного<span
style='letter-spacing:.05pt'> </span>кодексу<span style='letter-spacing:.05pt'>
</span>України<span style='letter-spacing:.05pt'> </span>укласти<span
style='letter-spacing:.05pt'> </span>Договір<span style='letter-spacing:.05pt'>
</span>про<span style='letter-spacing:.05pt'> </span>надання<span
style='letter-spacing:.05pt'> </span>послуг<span style='letter-spacing:.05pt'> </span>на<span
style='letter-spacing:.05pt'> </span>визначених<span style='letter-spacing:
-2.8pt'>            </span>умовах<span style='letter-spacing:-.05pt'> даного </span>Договору.</span></p>

<p class=MsoListParagraph style='margin-top:.1pt;margin-right:6.5pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>Договір – правочин, складений у формі договору приєднання
відповідно<span style='letter-spacing:.05pt'> </span>до ст. 634 Цивільного
кодексу України про надання послуг, умови якого встановлені<span
style='letter-spacing:-2.8pt'> </span>Виконавцем, і який може бути укладено
лише шляхом приєднання Замовника до<span style='letter-spacing:.05pt'> </span>запропонованого<span
style='letter-spacing:-.05pt'> </span>Договору в цілому.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.9pt;margin-bottom:0in;
margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:100%;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>1.3. Cайт<span style='letter-spacing:.05pt'> </span>Виконавця<span
style='letter-spacing:.05pt'> </span>–<span style='letter-spacing:.05pt'> </span>веб-сторінка<span
style='letter-spacing:.05pt'> </span>в<span style='letter-spacing:.05pt'> </span>мережі<span
style='letter-spacing:.05pt'> </span>Інтернет,<span style='letter-spacing:.05pt'>
</span>за<span style='letter-spacing:.05pt'> </span>адресою https://app.homeresort.city</span><span
lang=UK> </span><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>яка є основним джерелом інформування Замовника та/або<span
style='letter-spacing:.05pt'> </span>може<span style='letter-spacing:-.05pt'> </span>бути<span
style='letter-spacing:-.05pt'> </span>використаний<span style='letter-spacing:
-.05pt'> </span>Замовником<span style='letter-spacing:-.05pt'> </span>для<span
style='letter-spacing:-.05pt'> </span>замовлення<span style='letter-spacing:
-.05pt'> </span>послуг<span style='letter-spacing:-.05pt'> </span>Виконавця.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.9pt;margin-bottom:0in;
margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:100%;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>1.4. Замовник – юридична чи дієздатна фізична особа, яка Акцептувала
Публічну оферту<span style='letter-spacing:.05pt'> </span>Виконавця,<span
style='letter-spacing:-.05pt'> </span>викладену у цьому Договорі.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>1.5. Замовник<span style='letter-spacing:.05pt'> </span>повинен<span
style='letter-spacing:.05pt'> </span>ознайомитись<span style='letter-spacing:
.05pt'> </span>з<span style='letter-spacing:.05pt'> </span>умовами<span
style='letter-spacing:.05pt'> </span>Публічної<span style='letter-spacing:.05pt'>
</span>оферти<span style='letter-spacing:.05pt'> </span>та <span
style='letter-spacing:-2.8pt'> </span>підтвердити свою згоду з її умовами при
отриманні будь-яких послуг Виконавця</span><span lang=UK style='font-family:
"Times New Roman",serif'>, а також при замовленні будь-яких послуг через Сайт
Виконавця.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>1.6.
Персональні дані – відомості чи сукупність відомостей про юридичну чи фізичну
особу, яка ідентифікована або може бути конкретно ідентифікована.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>1.7.&nbsp;Комплекс&nbsp;–
Комплекс заміського відпочинку «Lake resort», надавачем послуг якого є Фізична
особа – підприємець Клешко Віолетта Сергіївна.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>1.8.&nbsp;Акцепт&nbsp;–
повна та безумовна згода Замовника на укладення Договору на умовах, визначених
публічною офертою, та прийняття Замовником всіх умов Договору без будь-яких
виключень, обмежень та застережень згідно з частиною 2 статті 642 Цивільного
кодексу України.<br>
1.9.&nbsp;Вартість послуг&nbsp;– затверджена Виконавцем вартість Послуг
(комплексу Послуг) у грошовому вираженні та правила їх застосування, згідно з
якими визначається розмір плати за надання кожного окремого виду Послуг
Виконавця.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>1.10.&nbsp;Послуга
(комплекс Послуг)&nbsp;– продукт діяльності Виконавця, спрямований на
задоволення потреб Замовника у сфері надання послуг на викладених в цьому
Договорі умовах.<br>
1.9.&nbsp;Припинення надання Послуг&nbsp;– припинення надання Послуг Виконавця
до усунення причин, що призвели до цього припинення, в тому числі у разі
невиконання Замовником своїх зобов’язань за Договором, не дотримання Замовником
правил відвідування Комплексу.<br>
1.10.&nbsp;Інструкції&nbsp;– відповідні положення щодо правил/умов користування
об’єктами/локаціями Комплексу, правил поведінки та техніки безпеки під час
користування видами Послуг, які надає Виконавець.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>1.11. Згода<span style='letter-spacing:.05pt'> </span>суб’єкта<span
style='letter-spacing:.05pt'> </span>персональних<span style='letter-spacing:
.05pt'> </span>даних<span style='letter-spacing:.05pt'> </span>–<span
style='letter-spacing:.05pt'> </span>добровільне<span style='letter-spacing:
.05pt'> </span>волевиявлення<span style='letter-spacing:.05pt'> юридичної чи </span>фізичної
особи, яка надає дозвіл на обробку її персональних даних відповідно до<span
style='letter-spacing:.05pt'> </span>мети<span style='letter-spacing:-.05pt'> </span>обробки.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:5.55pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>1.12. Обробка персональних даних – будь-яка дія або сукупність
дій, таких як<span style='letter-spacing:.05pt'> </span>збирання, реєстрація,
накопичення, зберігання, адаптування, зміна, поновлення,<span style='letter-spacing:
.05pt'> </span>використання і поширення (розповсюдження, реалізація, передача),
знеособлення,<span style='letter-spacing:-2.8pt'> </span>знищення<span
style='letter-spacing:.05pt'> </span>персональних<span style='letter-spacing:
.05pt'> </span>даних,<span style='letter-spacing:.05pt'> </span>у<span
style='letter-spacing:.05pt'> </span>тому<span style='letter-spacing:.05pt'> </span>числі<span
style='letter-spacing:.05pt'> </span>з<span style='letter-spacing:.05pt'> </span>використанням<span
style='letter-spacing:.05pt'> </span>інформаційних<span style='letter-spacing:
.05pt'> </span>(автоматизованих)<span style='letter-spacing:-.05pt'> </span>систем.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.9pt;margin-bottom:0in;
margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>1.13. Правила<span
style='letter-spacing:.05pt'> </span>надання<span style='letter-spacing:.05pt'>
</span>відповідних<span style='letter-spacing:.05pt'> </span>Послуг<span
style='letter-spacing:.05pt'> </span><i>–<span style='letter-spacing:.05pt'> </span></i>умови<span
style='letter-spacing:.05pt'> </span>надання<span style='letter-spacing:.05pt'>
</span>Послуг,<span style='letter-spacing:.05pt'> </span>які<span
style='letter-spacing:.05pt'> </span>обираються Замовником в рамках цього
Договору, є його невід’ємною частиною та<span style='letter-spacing:.05pt'> </span>є
єдиним джерелом врегулювання всіх відносин між Замовником та Виконавцем,<span
style='letter-spacing:.05pt'> </span>що<span style='letter-spacing:.05pt'> </span>виникають<span
style='letter-spacing:.05pt'> </span>в<span style='letter-spacing:.05pt'> </span>процесі<span
style='letter-spacing:.05pt'> </span>надання<span style='letter-spacing:.05pt'>
</span>Послуг.<span style='letter-spacing:.05pt'> </span>Всі<span
style='letter-spacing:.05pt'> </span>рекламні,<span style='letter-spacing:.05pt'>
</span>презентаційні<span style='letter-spacing:.05pt'> </span>та<span
style='letter-spacing:.05pt'> </span>інші<span style='letter-spacing:.05pt'> </span>матеріали,
що створені та існують з метою інформування про послуги Виконавця,<span
style='letter-spacing:.05pt'> </span>носять лише інформаційно-довідковий
характер і не є джерелом врегулювання всіх<span style='letter-spacing:-2.8pt'>                        
</span>умов<span style='letter-spacing:-.05pt'> </span>надання Послуг.</span></p>

<p class=MsoNormal align=center style='margin-right:-.35pt;text-align:center'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ЗАГАЛЬНІ ПОЛОЖЕННЯ</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-right:-.35pt'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>2.1. </span><span lang=UK style='font-family:"Times New Roman",serif'>Даний
Договір є офіційною публічною офертою Виконавця укласти Договір, за умовами
якого Виконавець зобов’язаний за встановлену Договором плату надати Замовнику
комплекс Послуг на викладених нижче умовах.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.2.
Даний Договір є публічним договором, відповідно до положень статті 633
Цивільного кодексу України, умови якого встановлюються однаковими для усіх
осіб, та укладається між Виконавцем і Замовником у формі договору приєднання
відповідно до статті 634 Цивільного кодексу України.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.3.
Сторони гарантують, що володіють необхідною цивільно-правовою дієздатністю, а
також усіма правами та повноваженнями, необхідними та достатніми для укладання
і виконання Договору відповідно до його умов.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.4.
Укладання договору означає, що Замовник у повному обсязі ознайомився та згодний
з правилами перебування на території Комплексу «Home Resort» і приймає всі
умови цього Договору без зауважень та заперечень.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.5.
Замовник у відповідності до статті 642 Цивільного кодексу України підтверджує
факт прийняття (акцепт) Публічної оферти у випадку виконання будь-якої з наступних
дій:</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.5.1.
заповнення Замовником реєстраційної картки на рецепції Комплексу та\або на
сайті Замовника.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.5.2.
здійснення оплати Замовником будь-якої Послуги, що надає Виконавець.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.5.3.
отримання Послуг (користування Послугами) Комплексу, які надає Замовник.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.5.4.
Бронювання (замовлення) Послуги за телефоном та/або через мережу Інтернет.<br>
2.6. Будь-яка окрема з вищевказаних в цьому пункті дій, яка виконана Замовником,
свідчить про те, що Замовник ознайомлений з даним Договором, згодний та приймає
умови даного Договору в повному обсязі.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.7.Термін
акцепту цієї публічної оферти необмежений.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.8.
Договір вважається укладеним і вступає в силу з моменту його Акцепту, та діє
протягом усього терміну перебування в Комплексі або до моменту розірвання
Договору з підстав, визначених умовами Договору та/або нормами чинного
законодавства України.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.9.
Укладання Договору означає, що Користувач:</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.9.1.
у повному обсязі ознайомився з функціонуванням та правилами Комплексу та іншими
додатковими Послугами, які надає Замовник і правилами поведінки, відвідування
та користування окремими Послугами, які викладені в тексті цього Договору або в
окремому додатку до Договору або витягу з цього Договору;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>2.9.3.
приймає всі умови цього Договору без зауважень та заперечень.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:6.05pt;margin-bottom:
0in;margin-left:7.1pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ПОРЯДОК ОПЛАТИ ПОСЛУГ</span></b></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>3.1.
Розрахунок проводиться в безготівковій формі на розрахунковий рахунок
Виконавця, зазначений в Рахунку шляхом передплати 100% вартості
послуг.</span></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>3.2.
Замовник за свій рахунок оплачує всі банківські послуги та/або комісії, які
необхідні для здійснення оплати за цим Договором.</span></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>3.3.
Днем здійснення оплати послуг Виконавця вважається день, в який сума, що
підлягає сплаті, зараховується на рахунок Виконавця.</span></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>3.4.
Кошти, сплачені Замовником, </span></p>

<p class=MsoListParagraphCxSpFirst><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpLast align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ПОРЯДОК ПОВЕДІНКИ ЗАМОВНИКІВ
НА ТЕРИТОРІЇ КОМПЛЕКСУ</span></b></p>

<p class=MsoNormal align=center style='margin-right:-.35pt;text-align:center'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-left:7.1pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>4.1.
Замовник зобов’язується дотримуватись умов цього Договору, Правил/умов
відвідування, поведінки та користування окремими Послугами, а також
підтримувати чистоту і порядок на всій території Комплексу. При порушенні умов
цього Договору та Правил відвідування, поведінки, та користування окремими
Послугами Замовник несе повну матеріальну та персональну відповідальність
згідно умов даного Договору та чинного законодавства України.<br>
4.2. У разі порушення Замовником умов цього Договору і, як наслідок,
одностороннього припинення договору Виконавцем, сума оплачених Замовником
Послуг Виконавця не повертається.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph align=center style='margin-right:-.35pt;text-align:
center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ВІДВІДУВАННЯ КОМПЛЕКСУ
НЕПОВНОЛІТНІМИ ОСОБАМИ </span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>5.1. Акцептуючи пропозицію
щодо укладення Договору оферти, Замовник зобов’язаний наглядати за своєю дитиною
та забезпечувати їй супровід, роз’яснити дитині загальні норми безпеки та
правил поведінки, правила користування океремими Послугами відповідно до Правил
відвідування, поведінки та користування окремими Послугами Комплексу та
слідкувати за дотриманням дитиною таких правил, оскільки дорослі несуть за дітей
повну відповідальність.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>5.2. Діти, віком до 16
(шістнадцяти) років зобов’язані відвідувати Комплекс лише у супроводі дорослих.
При цьому, самостійне (без супроводження батьків / законних представників)
поселення у готель можливе тільки при досягненні 18 років або набуття повної
цивільної дієздатності.<br>
5.3. Відповідальність за безпеку неповнолітніх дітей несуть їхні батьки або
дорослі, які їх супроводжують. Дорослим забороняється залишати дітей без
нагляду на території Комплексу.<br>
5.4. Відповідальність за травмування та погіршення стану здоров’я дитини у разі
недотримання загальних норм безпеки, свідомого ігнорування рекомендацій
персоналу Виконавця, Правил відвідування, поведінки та користування окремими
Послугами Комплексу, несе дорослий, який супроводжує дитину. Виконавець та його
персонал у цьому випадку відповідальність не несуть.</span></p>

<p class=MsoNormal style='margin-right:-.35pt'><b><span lang=UK
style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph align=center style='margin-right:-.35pt;text-align:
center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ПРАВА ТА ОБОВ’ЯЗКИ
ЗАМОВНИКА</span></b></p>

<p class=MsoNormal style='margin-right:-.35pt'><b><span lang=UK
style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
lang=UK style='font-family:"Times New Roman",serif'>6.1. Замовник має право:</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.1. Отримувати необхідну
та достовірну інформацію про Виконавця, обсяг наданих ним Послуг, а також іншу
інформацію, пов’язану з наданням Послуг.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.2. Користуватись усіма
Послугами Комплексу згідно умов даного Договору.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.3. Здійснювати контроль
за повнотою надання Послуг Виконавцем.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.4. Вимагати від
Виконавця якісного надання Послуг згідно з вимогами чинного законодавства та
належного виконання умов цього Договору. </span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.5. Відмовитись від отримання
послуг, якщо надані послуги не відповідають умовам Договору і вимагати від
Виконавця повернення сплачених коштів, у разі невиконання або неналежного
виконання Виконавцем взятих на  себе обов'язків за цим Договором.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.1.6. Повернути сплачені
кошти за послуги Виконавця, якщо такі послуги надані не були або у разі відмови
Замовника від отримання послуг Виконавця. Для повернення сплачених коштів,
Замовнику необхідно письмово повідомити Виконавця про відмову від отримання
послуг за 24 години до початку надання таких послуг. </span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
lang=UK style='font-family:"Times New Roman",serif'>6.2. Замовник
зобов’язується:</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.1. Надавати повну та
достовірну інформацію, необхідну для ідентифікації Замовника, що відповідають
дійсності та не суперечать положенням Закону України «Про захист персональних
даних» та чинному законодавству України.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.2. <span
style='color:black'>Повідомити прізвище, ім’я та по батькові, засіб зв’язку,
адресу місця<span style='letter-spacing:.05pt'> </span>надання<span
style='letter-spacing:.05pt'> </span>послуг,<span style='letter-spacing:.05pt'>
</span>суть<span style='letter-spacing:.05pt'> </span>послуги,<span
style='letter-spacing:.05pt'> </span>а<span style='letter-spacing:.05pt'> </span>також<span
style='letter-spacing:.05pt'> </span>іншу<span style='letter-spacing:.05pt'> </span>додаткову<span
style='letter-spacing:.05pt'> </span>інформацію<span style='letter-spacing:
.05pt'> </span>при<span style='letter-spacing:.05pt'> </span>її<span
style='letter-spacing:.05pt'> </span>запиті<span style='letter-spacing:-2.85pt'>           
</span>Виконавцем.</span></span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>6.2.3. При зверненні до
Виконавця за наданням послуг, не порушувати<span style='letter-spacing:.05pt'> </span>вимог<span
style='letter-spacing:-.4pt'> </span>чинного<span style='letter-spacing:-.4pt'>
</span>законодавства<span style='letter-spacing:-.4pt'> </span>та<span
style='letter-spacing:-.4pt'> </span>прийнятих<span style='letter-spacing:-.4pt'>
</span>суспільством<span style='letter-spacing:-.4pt'> </span>норм<span
style='letter-spacing:-.4pt'> </span>моралі<span style='letter-spacing:-.4pt'> </span>та<span
style='letter-spacing:-.4pt'> </span>моральності,<span style='letter-spacing:
-2.85pt'> </span>не використовувати результати надання послуг Виконавцем у
протиправних цілях, в<span style='letter-spacing:-2.8pt'> </span>результаті
чого можуть бути порушені права та законні інтереси Виконавця та/або<span
style='letter-spacing:.05pt'> </span>третіх<span style='letter-spacing:-.05pt'>
</span>осіб.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>6.2.4. Своєчасно<span
style='letter-spacing:.05pt'> </span>оплачувати<span style='letter-spacing:
.05pt'> </span>послуги<span style='letter-spacing:.05pt'> </span>Виконавця.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.5. Перед оплатою Послуг
ознайомитися з умовами цього Договору, безумовно дотримуватися умов цього
Договору упродовж усього періоду користування Послугами.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.6. Здійснити оплату
Послуг. Замовник зобов’язаний здійснювати оплату Послуг лише тим шляхом, що
передбачений умовами цього Договору, або за домовленістю Сторін у інший спосіб,
що передбачений чинним законодавством України. </span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.7. Перед початком
користування Послугою, уважно ознайомитись з відповідними Правилами
відвідування, поведінки та користування окремими Послугами Комплексу.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.2.8. При користуванні
Послугами Комплексу виконувати законні вимоги працівників Виконавця та
дотримуватись умов цього Договору та Правил відвідування, поведінки та
користування окремими Послугами Комплексу.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>6.2.9. У<span
style='letter-spacing:.05pt'> </span>разі<span style='letter-spacing:.05pt'> </span>виникнення<span
style='letter-spacing:.05pt'> </span>претензій<span style='letter-spacing:.05pt'>
</span>щодо<span style='letter-spacing:.05pt'> </span>наданих<span
style='letter-spacing:.05pt'> </span>Виконавцем<span style='letter-spacing:
.05pt'> </span>послуг<span style='letter-spacing:.05pt'> </span>письмово<span
style='letter-spacing:.05pt'> </span>у<span style='letter-spacing:.05pt'> </span>вільній<span
style='letter-spacing:.05pt'> </span>формі<span style='letter-spacing:.05pt'> </span>повідомити<span
style='letter-spacing:.05pt'> </span>про<span style='letter-spacing:.05pt'> </span>це<span
style='letter-spacing:.05pt'> </span>Виконавця<span style='letter-spacing:.05pt'>
</span>протягом<span style='letter-spacing:.05pt'> </span>3<span
style='letter-spacing:.05pt'> </span>(трьох)<span style='letter-spacing:.05pt'>
</span>календарних днів з моменту, коли Замовникові стало відомо про
невиконання або<span style='letter-spacing:.05pt'> </span>неналежне<span
style='letter-spacing:-.05pt'> </span>виконання<span style='letter-spacing:
-.05pt'> </span>Виконавцем<span style='letter-spacing:-.05pt'> </span>його<span
style='letter-spacing:-.05pt'> </span>зобов'язань за<span style='letter-spacing:
-.05pt'> </span>цим<span style='letter-spacing:-.05pt'> </span>Договором.</span></p>

<p class=MsoListParagraph style='margin-top:.15pt;margin-right:5.7pt;
margin-bottom:0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;
text-justify:inter-ideograph;text-autospace:none'><span lang=UK
style='font-family:"Times New Roman",serif;color:black'>6.2.10. Дотримуватися<span
style='letter-spacing:.05pt'> </span>законодавства<span style='letter-spacing:
.05pt'> </span>України,<span style='letter-spacing:.05pt'> </span>що<span
style='letter-spacing:.05pt'> </span>стосується<span style='letter-spacing:
.05pt'> </span>охорони<span style='letter-spacing:.05pt'> </span>авторських<span
style='letter-spacing:-.05pt'> </span>прав Виконавця.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
lang=UK style='font-family:"Times New Roman",serif'>6.3. Виконавець має право:</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.3.1. <span
style='color:black'>Залучати<span style='letter-spacing:.05pt'> </span>для<span
style='letter-spacing:.05pt'> </span>здійснення<span style='letter-spacing:
.05pt'> </span>своїх<span style='letter-spacing:.05pt'> </span>обов’язків<span
style='letter-spacing:.05pt'> </span>третіх<span style='letter-spacing:.05pt'> </span>осіб,<span
style='letter-spacing:.05pt'> </span>які<span style='letter-spacing:.05pt'> </span>мають<span
style='letter-spacing:.05pt'> </span>необхідну<span style='letter-spacing:-.05pt'>
</span>кваліфікацію для<span style='letter-spacing:-.05pt'> </span>надання
конкретного<span style='letter-spacing:-.05pt'> </span>виду Послуги.</span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.3.2. <span
style='color:black'>Перевіряти<span style='letter-spacing:.05pt'> </span>надану<span
style='letter-spacing:.05pt'> </span>Замовником<span style='letter-spacing:
.05pt'> </span>інформацію,<span style='letter-spacing:.05pt'> </span>а<span
style='letter-spacing:.05pt'> </span>також<span style='letter-spacing:.05pt'> </span>виконання<span
style='letter-spacing:.05pt'> </span>Замовником<span style='letter-spacing:
-.05pt'> </span>умов Договору.</span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.3.3. <span
style='color:black'>Відмовити Замовнику у наданні Послуг у випадках, якщо:<span
style='letter-spacing:-2.8pt'> </span></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>а)<span
style='letter-spacing:-.1pt'> </span>Замовник<span style='letter-spacing:-.1pt'>
</span>порушив<span style='letter-spacing:-.1pt'> </span>свої<span
style='letter-spacing:-.05pt'> </span>зобов’язання<span style='letter-spacing:
-.1pt'> </span>щодо<span style='letter-spacing:-.1pt'> </span>оплати<span
style='letter-spacing:-.1pt'> </span>Послуг;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>б)<span
style='letter-spacing:-.65pt'> </span>Замовник<span style='letter-spacing:-.65pt'>
</span>використовує<span style='letter-spacing:-.65pt'> </span>результати<span
style='letter-spacing:-.65pt'> </span>наданих<span style='letter-spacing:-.65pt'>
</span>Послуг<span style='letter-spacing:-.65pt'> </span>у<span
style='letter-spacing:-.65pt'> </span>протиправних<span style='letter-spacing:
-.65pt'> </span>цілях,<span style='letter-spacing:-2.8pt'> </span>що шкодить
або може зашкодити правам та законним інтересам Виконавця та/або<span
style='letter-spacing:.05pt'> </span>третіх<span style='letter-spacing:-.05pt'>
</span>осіб;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>в)<span
style='letter-spacing:.55pt'> Не допускати </span></span><span lang=UK
style='font-family:"Times New Roman",serif'>до користування Послугами, або
припиняти надання Послуг відносно осіб у стані алкогольного, наркотичного
сп’яніння та в інших випадках, без пояснення причин відмовлення в наданні
Послуг, якщо на думку працівників Виконавця, ця особа викликає сумнів щодо її
здатності адекватно розуміти та керувати своїми діями, несе загрозу <span
style='color:black'>життю<span style='letter-spacing:.2pt'> </span>та<span
style='letter-spacing:.15pt'> </span>здоров’ю Виконавця та/або третім особам</span>
та неухильно дотримуватись умов цього Договору, відповідних Правил
відвідування, поведінки та користування окремими Послугами Комплексу, не
пояснюючи Замовнику причин.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>г)<span
style='letter-spacing:-.1pt'> </span>Замовник<span style='letter-spacing:-.1pt'>
</span>не<span style='letter-spacing:-.1pt'> </span>згоден<span
style='letter-spacing:-.1pt'> </span>з<span style='letter-spacing:-.1pt'> </span>умовами<span
style='letter-spacing:-.1pt'> </span>даного<span style='letter-spacing:-.1pt'> </span>Договору.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>6.3.4. Змінювати<span
style='letter-spacing:.05pt'> </span>або<span style='letter-spacing:.05pt'> </span>доповнювати<span
style='letter-spacing:.05pt'> </span>Договір<span style='letter-spacing:.05pt'>
</span>в<span style='letter-spacing:.05pt'> </span>будь-який<span
style='letter-spacing:.05pt'> </span>момент<span style='letter-spacing:.05pt'> </span>без<span
style='letter-spacing:.05pt'> </span>попереднього або наступного повідомлення,
що оприлюднюється за 10 днів до<span style='letter-spacing:.05pt'> </span>набрання<span
style='letter-spacing:-.3pt'> </span>ним<span style='letter-spacing:-.3pt'> </span>чинності<span
style='letter-spacing:-.25pt'> </span>і<span style='letter-spacing:-.3pt'> </span>доводиться<span
style='letter-spacing:-.25pt'> </span>до<span style='letter-spacing:-.3pt'> </span>загального<span
style='letter-spacing:-.25pt'> </span>відома<span style='letter-spacing:-.3pt'>
</span>за<span style='letter-spacing:-.25pt'> </span>допомогою<span
style='letter-spacing:-.3pt'> </span>публікації<span style='letter-spacing:
-2.8pt'> </span>на<span style='letter-spacing:.05pt'> </span>Сайті </span><a
href="https://app.homeresort.city"><span lang=UK style='font-family:"Times New Roman",serif'>https://app.homeresort.city</span></a><span
lang=UK style='font-family:"Times New Roman",serif;color:black'>.<span
style='letter-spacing:.05pt'> </span>Обов'язок<span style='letter-spacing:.05pt'>
</span>ознайомлення<span style='letter-spacing:.05pt'> </span>з<span
style='letter-spacing:.05pt'> </span>діючою<span style='letter-spacing:.05pt'> </span>редакцією<span
style='letter-spacing:.05pt'> </span>Договору<span style='letter-spacing:.05pt'>
</span>покладається<span style='letter-spacing:.05pt'> </span>на<span
style='letter-spacing:.05pt'> </span>Замовника.<span style='letter-spacing:
.05pt'> </span>Продовження<span style='letter-spacing:.05pt'> </span>використання<span
style='letter-spacing:.05pt'> </span>мобільним<span style='letter-spacing:.05pt'>
</span>додатком Замовником після внесення змін і/або доповнень до Договору
означає<span style='letter-spacing:.05pt'> </span>прийняття<span
style='letter-spacing:-.05pt'> </span>і<span style='letter-spacing:-.05pt'> </span>згоду
Користувача<span style='letter-spacing:-.05pt'> </span>з<span style='letter-spacing:
-.05pt'> </span>такими змінами<span style='letter-spacing:-.05pt'> </span>і/або<span
style='letter-spacing:-.1pt'> </span>доповненнями.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif;color:black;letter-spacing:
.05pt'>6.3.5. </span><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>Проводити<span style='letter-spacing:.55pt'> </span>аудіо<span
style='letter-spacing:.6pt'> </span>запис<span style='letter-spacing:.6pt'> </span>всіх<span
style='letter-spacing:.55pt'> </span>розмов<span style='letter-spacing:.6pt'> </span>з<span
style='letter-spacing:.6pt'> </span>Замовником<span style='letter-spacing:.55pt'>
</span>без<span style='letter-spacing:-2.8pt'> </span>додаткового<span
style='letter-spacing:-.15pt'> </span>повідомлення<span style='letter-spacing:
-.15pt'> </span>Замовника<span style='letter-spacing:-.1pt'> </span>про<span
style='letter-spacing:-.15pt'> </span>це.<span style='letter-spacing:-.1pt'> </span>Дана<span
style='letter-spacing:-.15pt'> </span>інформація<span style='letter-spacing:
-.1pt'> </span>є<span style='letter-spacing:-.15pt'> </span>конфіденційною.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.3.6. </span><span
lang=UK style='font-family:"Times New Roman",serif'>У разі грубого та/або
неодноразового порушення умов цього Договору, не реагування Замовником, на
зауваження працівників Виконавця, якщо при цьому дії Замовника, будуть
спрямовані на нанесення небезпеки для здоров’я, майна, честі або гідності інших
осіб та/або для працівників Виконавця, Виконавець має право припинити надання
Послуг такому Замовнику, блокувати його незаконні дії та/або запропонувати
покинути територію Комплексу. У разі скоєння Замовником дій, які підлягають
ознакам адміністративного правопорушення, або кримінального злочину, працівники
Виконавця у такому випадку мають право діяти у відповідності до вимог чинного
законодавства України щодо цього Замовника та викликати представників
правоохоронних органів.<br>
6.3.7. У випадку неможливості Замовника провести кінцевий розрахунок за надані
Послуги та/або за надані збитки Виконавцю, Виконавець має право вимагати від Замовника
оформлення боргової розписки, яка має силу правочинну, щодо підтвердження
вказаного боргу та забезпечення&nbsp; виконання&nbsp; зобов’язання, яке вчинено
у письмовій формі, у відповідності до вимог ст. 547 – 548 Цивільного кодексу
України.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
lang=UK style='font-family:"Times New Roman",serif'>6.4. Виконавець зобов'язується:</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.4.1. </span><span
lang=UK style='font-family:"Times New Roman",serif'>Дотримуватись вимог чинного
законодавства України щодо захисту персональних даних Замовників, які
Виконавець отримав в процесі виконання цього Договору.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.4.2. Надавати послуги
якісно і професійно, за допомогою кваліфікованих фахівців, які пройшли
відповідну підготовку.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>6.4.3. За письмовою вимогою
Замовника повернути сплачені кошти за послуги Виконавця у разі відмови
Замовника від отримання таких послуг, за умови повідомлення Замовником про таку
відмову за 24 години до початку надання таких послуг Виконавцем.</span></p>

<p class=MsoNormal style='margin-right:-.35pt'><b><span lang=UK
style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph align=center style='margin-right:-.35pt;text-align:
center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ВІДПОВІДАЛЬНІСТЬ ЗА
ПОРУШЕННЯ УМОВ ДОГОВОРУ</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.1. У випадку порушення
своїх зобов’язань за цим Договором Сторони несуть відповідальність, визначену
цим Договором та чинним законодавством України. Порушенням зобов’язання є його невиконання
або неналежне виконання, тобто виконання з порушенням умов, визначених змістом
зобов’язання за цим Договором.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.2. Сторона не несе
відповідальності за порушення Договору, якщо воно сталося не з її вини (умислу
чи необережності). Сторона вважається невинуватою і не несе відповідальності за
порушення Договору, якщо вона доведе, що вжила всіх залежних від неї заходів
щодо належного виконання цього Договору.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.2. Виконавець
уповноважений застосовувати заходи відповідно до умов цього Договору та чинного
законодавства України у разі скоєння Замовником адміністративного
правопорушення, кримінального злочину.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.3. Недотримання Правил
відвідування, поведінки та користування окремими Послугами Комплексу,
викладених у цьому Договорі, або спроба перешкоджати іншим особам і працівникам
Виконавця їх виконувати та користуватися Послугами згідно умов цього Договору,
є підставою та правом для Виконавця вимагати від такого Замовника негайно
залишити територію або здійснити примусове виведення Замовника з території Комплексу
у разі його незгоди добровільно залишити територію (крім випадків коли Замовник
скоїв адміністративне правопорушення, або кримінальний злочин і проводиться
його затримання до приїзду правоохоронних органів). Замовнику, що порушує умови
цього Договору, може бути відмовлено в праві наступного відвідування Комплексу.<br>
7.4. Замовник, зобов’язаний в повному обсязі відшкодувати шкоду, яку завдано
іншим Замовникам, Виконавцю та/або третім особам.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.5. При порушенні умов
цього Договору та інших правил, що затверджені Виконавцем, поведінки та
користування окремими Послугами Комплексу Замовник несе повну матеріальну та
персональну відповідальність згідно з даним Договором, чинним законодавством
України.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.6. У випадку втрати та/або
пошкодження майна Комплексу з вини Замовника, він зобов’язаний повною мірою
відшкодувати заподіяну матеріальну шкоду.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.7. Замовник несе іншу
відповідальність, передбачену умовами цього Договору та чинним законодавством
України.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>7.8. Виконавець не несе
відповідальності за шкоду, яка була отримана Замовниками, в тому числі, їхніми
дітьми, внаслідок нещасного випадку, отриманої травми та погіршення стану
здоров’я&nbsp; (в тому числі, викликані тваринами, рослинами, грибами), або
чогось іншого, що виникло через недбалість зі сторони Замовника/Замовників
та/або третіх осіб, та/або внаслідок невиконання або неналежного виконання умов
цього Договору, &nbsp;Правил відвідування, поведінки та користування окремими
Послугами Комплексу та рекомендацій персоналу Виконавця, загальних правил
безпеки.<br>
7.9. Виконавець не несе відповідальності за збереження речей Замовника/Замовників,
в тому числі, речей їхніх дітей, на території Комплексу та в готельних номерах.</span></p>

<p class=MsoNormal align=center style='margin-right:-.35pt;text-align:center'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ЗМІНА І РОЗІРВАННЯ ДОГОВОРУ</span></b></p>

<p class=MsoListParagraphCxSpLast style='text-align:justify;text-justify:inter-ideograph'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.1. Виконавець має право змінювати або доповнювати Договір в
будь-який<span style='letter-spacing:.05pt'> </span>момент без попереднього або
наступного повідомлення, що оприлюднюється за 10<span style='letter-spacing:
-2.8pt'> </span>днів до набрання чинності змін до Договору або затвердження
нової редакції Договору і доводяться до загального відома за допомогою<span
style='letter-spacing:.05pt'> </span>публікації<span style='letter-spacing:
.05pt'> </span>на<span style='letter-spacing:.05pt'> </span>Сайті.<span
style='letter-spacing:.05pt'> </span>Обов'язок<span style='letter-spacing:.05pt'>
</span>ознайомлення<span style='letter-spacing:.05pt'> </span>з<span
style='letter-spacing:.05pt'> </span>діючою<span style='letter-spacing:.05pt'> </span>редакцією<span
style='letter-spacing:.05pt'> </span>Договору<span style='letter-spacing:.05pt'>
</span>покладається<span style='letter-spacing:.05pt'> </span>на<span
style='letter-spacing:.05pt'> </span>Замовника.<span style='letter-spacing:
.05pt'> </span>Продовження<span style='letter-spacing:.05pt'> </span>використання<span
style='letter-spacing:.05pt'> </span>мобільного<span style='letter-spacing:
.05pt'> </span>додатку<span style='letter-spacing:.05pt'> </span>Замовником
після внесення змін та/або доповнень до Договору та/або затвердження нової
редакції Договору означає прийняття і<span style='letter-spacing:.05pt'> </span>згоду<span
style='letter-spacing:-.05pt'> </span>Замовника з<span style='letter-spacing:
-.05pt'> </span>такими змінами та/або<span style='letter-spacing:-.1pt'> </span>доповненнями,
та/або згоду Замовника на виконання нової редакції Договору.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.2. У<span style='letter-spacing:.05pt'> </span>разі<span
style='letter-spacing:.05pt'> </span>незгоди<span style='letter-spacing:.05pt'>
</span>Замовника<span style='letter-spacing:.05pt'> </span>зі<span
style='letter-spacing:.05pt'> </span>змінами<span style='letter-spacing:.05pt'>
</span>та/або<span style='letter-spacing:.05pt'> </span>доповненнями,<span
style='letter-spacing:.05pt'> </span>внесеними<span style='letter-spacing:.05pt'>
</span>Виконавцем до тексту Оферти, або незгоди з нової редакцією Договору
та/або новими тарифами на Послугу, Замовник<span style='letter-spacing:.05pt'> </span>повинен<span
style='letter-spacing:-.05pt'> </span>припинити користування послугами
Виконавця.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.3. Кожна<span style='letter-spacing:.05pt'> </span>Сторона<span
style='letter-spacing:.05pt'> </span>гарантує<span style='letter-spacing:.05pt'>
</span>іншій<span style='letter-spacing:.05pt'> </span>Стороні,<span
style='letter-spacing:.05pt'> </span>що<span style='letter-spacing:.05pt'> </span>володіє<span
style='letter-spacing:.05pt'> </span>необхідною<span style='letter-spacing:
.05pt'> </span>дієздатністю, а також всіма правами і повноваженнями,
необхідними і достатніми<span style='letter-spacing:.05pt'> </span>для<span
style='letter-spacing:-.05pt'> </span>укладання і<span style='letter-spacing:
-.05pt'> </span>виконання Договору<span style='letter-spacing:-.05pt'> </span>відповідно
до його<span style='letter-spacing:-.05pt'> </span>умов.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.4. Замовник<span style='letter-spacing:-.6pt'> </span>також<span
style='letter-spacing:-.5pt'> </span>підтверджує,<span style='letter-spacing:
-.55pt'> </span>що<span style='letter-spacing:-.55pt'> </span>не<span
style='letter-spacing:-.55pt'> </span>існує<span style='letter-spacing:-.55pt'>
</span>будь-яких<span style='letter-spacing:-.6pt'> </span>обставин<span
style='letter-spacing:-.55pt'> </span>через<span style='letter-spacing:-.5pt'> </span>які<span
style='letter-spacing:-.55pt'> </span>можна<span style='letter-spacing:-2.85pt'>
</span>було<span style='letter-spacing:-.05pt'> </span>б визнати такий договір недійсним/нікчемним.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.5. Цей<span style='letter-spacing:.05pt'> </span>Договір<span
style='letter-spacing:.05pt'> </span>є<span style='letter-spacing:.05pt'> </span>публічним<span
style='letter-spacing:.05pt'> </span>в<span style='letter-spacing:.05pt'> </span>розумінні<span
style='letter-spacing:.05pt'> </span>статті<span style='letter-spacing:.05pt'> </span>633<span
style='letter-spacing:.05pt'> </span>Цивільного<span style='letter-spacing:
.05pt'> </span>кодексу<span style='letter-spacing:.05pt'> </span>України.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.6. Місцем<span style='letter-spacing:-.15pt'> </span>укладення<span
style='letter-spacing:-.1pt'> </span>Договору<span style='letter-spacing:-.1pt'>
</span>є<span style='letter-spacing:-.1pt'> </span>Україна, Київська область,
Бучанський район, с. Березівка.</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.7. При<span style='letter-spacing:.05pt'> </span>приєднанні<span
style='letter-spacing:.05pt'> </span>до<span style='letter-spacing:.05pt'> </span>даної<span
style='letter-spacing:.05pt'> </span>Публічної<span style='letter-spacing:.05pt'>
</span>оферти<span style='letter-spacing:.05pt'> </span>Замовник<span
style='letter-spacing:.05pt'> </span>надає<span style='letter-spacing:.05pt'> </span>згоду<span
style='letter-spacing:.05pt'> </span>Виконавцю на використання та обробку його
персональних даних в рамках Закону<span style='letter-spacing:.05pt'> </span>України<span
style='letter-spacing:-.05pt'> </span>«Про захист персональних даних».</span></p>

<p class=MsoNormal style='margin-right:5.85pt;text-align:justify;text-justify:
inter-ideograph;text-autospace:none'><span lang=UK style='font-family:"Times New Roman",serif;
color:black'>8.8. До акцептування Пропозиції Замовник зобов’язаний ознайомитись
з умовами цього Договору, якщо Замовник не згодний з умовами Договору та/або
вважає умови Договору, що є явно обтяжливими для Замовника, Замовник не має
права вчиняти дії, спрямовані на укладання та виконання Договору. </span><span
lang=UK style='font-family:"Times New Roman",serif;color:black;background:white'>Після
акцептування Заявником Договору, всі умови Договору є обов’язковими для
Виконавця та Заявника. Заявник акцептує Договір в цілому. Замовник не може
запропонувати свої умови Договору.</span></p>

<p class=MsoNormal><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph align=center style='margin-right:-.35pt;text-align:
center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=UK style='font-family:"Times New Roman",serif'>ПОРЯДОК РОЗГЛЯДУ СКАРГ ТА ВИРІШЕННЯ
СУПЕРЕЧОК</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>9.1. Всі суперечки і
розбіжності, що виникли або могли виникнути з приводу даного Договору,
підлягають вирішенню шляхом переговорів.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>9.2. Після отримання від Замовника
скарги Виконавець зобов’язаний протягом 30 (тридцяти) календарних днів
розглянути, та прийняти рішення відносно вирішення правомірності задоволення
заяви Замовника й письмово повідомити його про кінцеве рішення.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=UK style='font-family:"Times New Roman",serif'>9.3. У випадку, якщо
суперечка, що виникла, не буде вирішена в претензійному порядку або порядку
переговорів, будь-яка із Сторін має право звернутися за розв’язанням суперечки
до суду відповідно до чинного законодавства України.</span></p>

<p class=MsoListParagraphCxSpFirst><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpLast align=center style='margin-right:-.35pt;
text-align:center;text-indent:-.25in'><b><span lang=UK style='font-family:"Times New Roman",serif'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=UK
style='font-family:"Times New Roman",serif'>РЕКВІЗИТИ ВИКОНАВЦЯ</span></b></p>

<p class=MsoNormal align=center style='margin-right:-.35pt;text-align:center'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><b><span lang=UK style='font-family:"Times New Roman",serif'>Фізична
особа – підприємець</span></b></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><b><span lang=UK style='font-family:"Times New Roman",serif'>Клешко Віолетта Сергіївна</span></b></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>Дата
та номер запису в Єдиному державному реєстрі юридичних осіб, </span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>фізичних
осіб – підприємців та громадських формувань:</span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>06.07.2024р.
2010350000000594514;</span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>Банківські
реквізити: </span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>IBAN:
UA563052990000026004000127391</span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>ЄДРПОУ:
3747108606</span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><span lang=UK style='font-family:"Times New Roman",serif'>ФОП КЛЕШКО ВІОЛЕТТА СЕРГІЇВНА</span></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-right:-.35pt;text-align:justify;text-justify:
inter-ideograph'><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph><b><span lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-right:-.35pt;text-align:center'><b><span
lang=UK style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal><span lang=UK>&nbsp;</span></p>

</div>

</body>

</html>
`
