import React, {useState} from "react";
import {Box, Flex, IconButton, Text} from "@radix-ui/themes";
import ScanQRModal from "../features/ScanQR/ScanQRModal";
import {
    BookOpenIcon,
    QrCodeIcon,
    RocketLaunchIcon,
    SunIcon,
    TicketIcon,
    UserCircleIcon
} from "@heroicons/react/24/outline";
import BottomNavElement from "./BottomNavElement";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../store/hooks";

export default function BottomNav() {

    const [isModalOpened, setIsModalOpened] = useState(false);

    const navigate = useNavigate();

    const { isUserLoggedIn } = useAppSelector(state => state.authentication);

    // @ts-ignore
    const isFullScreen: boolean | null = window.navigator.standalone;

    return (
        <>
            <Flex
                position='absolute'
                bottom='0'
                p='2'
                px='4'
                pb='4'
                width="100%"
                height={isFullScreen ? '68px' : '56px'}
                justify="between"
                className="bottom-nav-container"
                style={{zIndex: 0}}
            >
                <BottomNavElement
                    label="Дашборд"
                    icon={<RocketLaunchIcon />}
                    onClick={() => navigate('/')}
                />

                <BottomNavElement
                    label="Білети"
                    icon={<TicketIcon />}
                    onClick={() => navigate('/entry-ticket')}
                />

                {
                    isUserLoggedIn && (
                        <BottomNavElement
                            onClick={() => setIsModalOpened(true)}
                            label="Скан QR"
                            icon={<QrCodeIcon />}
                        />
                    )
                }

                <BottomNavElement
                    label="Резервації"
                    icon={<BookOpenIcon />}
                    onClick={() => navigate('/reservations-list')}
                />

                <BottomNavElement
                    label={isUserLoggedIn ? "Профіль" : "Увійти"}
                    icon={<UserCircleIcon />}
                    onClick={() => {
                        if (isUserLoggedIn) {
                            navigate('/profile')
                        } else {
                            navigate('/login')
                        }
                    }}
                />
            </Flex>
            {/*Must be outside positioned parent*/}
            <ScanQRModal open={isModalOpened} setOpen={setIsModalOpened} />
        </>
    )
}
