import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {Services} from "../../types/services";
import {content} from "../../helpers/content";

export default function GlampingList() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Глемпінг</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.GLAMPING_STANDARD].heroImage}
                    galleryImages={content[Services.GLAMPING_STANDARD].galleryImages}
                    description={content[Services.GLAMPING_STANDARD].description}
                    service={Services.GLAMPING_STANDARD}
                />
                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.GLAMPING_STANDARD_BIG].heroImage}
                    galleryImages={content[Services.GLAMPING_STANDARD_BIG].galleryImages}
                    description={content[Services.GLAMPING_STANDARD_BIG].description}
                    service={Services.GLAMPING_STANDARD_BIG}
                />
                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.GLAMPING_COMFORT].heroImage}
                    galleryImages={content[Services.GLAMPING_COMFORT].galleryImages}
                    description={content[Services.GLAMPING_COMFORT].description}
                    service={Services.GLAMPING_COMFORT}
                    // disabledDescription="Вже скоро"
                />
            </Flex>
        </Flex>
    )
}
