import React from "react";
import {Box, Button, Card, Flex, Text} from "@radix-ui/themes";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

export default function SuccessPasswordRecovery() {

    const navigate = useNavigate();

    return (
        <Card size="2" variant="classic" className="dashboard-feature__card">
            <Flex justify="center">
                <CheckCircleIcon color="var(--teal-9)" width="36px" height="36px" />
            </Flex>
            <Box mt="4">
                <Text size="2">
                    Очікуйте на смс до трьох хвилин з паролем для входу.
                </Text>

                <Flex justify="center" mt="4">
                    <Button size="3" onClick={() => navigate('/login')}>
                        Увійти
                    </Button>
                </Flex>
            </Box>
        </Card>
    );
}
