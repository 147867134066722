import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'phoneNumber' | 'name' | 'surname' | 'email' | 'acceptRules';

export const rulesLoginForm: RulesForFieldsType<ValidationKeys> = {
  phoneNumber: {
    userFriendlyFieldName: 'Номер телефону',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 13,
    maxLength: 13,
  },
  name: {
    userFriendlyFieldName: 'Імʼя',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 64,
  },
  surname: {
    userFriendlyFieldName: 'Прізвище',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 3,
    maxLength: 64,
  },
  email: {
    userFriendlyFieldName: 'Пошта',
    ruleType: RuleTypes.email,
    required: false,
  },
  acceptRules: {
    userFriendlyFieldName: 'Погоджуюсь з правилами',
    ruleType: RuleTypes.flag,
    required: true,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  phoneNumber: { errorMessage: '' },
  name: { errorMessage: '' },
  surname: { errorMessage: '' },
  email: { errorMessage: '' },
  acceptRules: { errorMessage: '' },
};
