import React from "react";
import {Button, Flex} from "@radix-ui/themes";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {PaginatedResponse} from "../types/pagination";

interface PaginationProps extends Omit<PaginatedResponse<any>, 'data'> {
    setPage: (page: number) => void;
    isLoading: boolean,
}

export default function Pagination(props: PaginationProps) {

    const { setPage, page, totalPages, isLoading } = props;

    if (totalPages === 1) return null;

    return (
        <Flex gap="2" justify="end">
            <Button
                onClick={() => setPage(page - 1)}
                disabled={isLoading || page === 1}
            >
                <ChevronLeftIcon width="16" height="16" />
                Назад
            </Button>
            <Button
                disabled={isLoading || page === totalPages}
                onClick={() => setPage(page + 1)}
            >
                Вперед
                <ChevronRightIcon width="16" height="16" />
            </Button>
        </Flex>
    )
}
