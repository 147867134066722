import React, {useEffect, useState} from "react";
import {Text} from "@radix-ui/themes";
import {Services} from "../types/services";
import {useGetSpotsCalculationQuery} from "../features/booking/bookingAPI";

interface FreeSpotsProps {
    service: Services,
    date?: string,
    setParentFreeSpots: (number: number) => void,
}

export default function FreeSpots(props: FreeSpotsProps) {

    const [freeSpots, setFreeSpots] = useState(1);

    const { service, date, setParentFreeSpots } = props;

    const { data } = useGetSpotsCalculationQuery({ date }, { skip: !date});

    useEffect(() => {
        if (! data || ! setParentFreeSpots) return;

        if (service === Services.ENTRY_TICKET) {
            setFreeSpots(data.numberOfEntryTickets);
            setParentFreeSpots(data.numberOfEntryTickets);
            return;
        }

        if (service === Services.SUN_LOUNGERS) {
            setFreeSpots(data.numberOfSunLoungers);
            setParentFreeSpots(data.numberOfSunLoungers);
            return;
        }

        if (service === Services.SUN_LOUNGERS_WITH_TABLE) {
            setFreeSpots(data.numberOfSunLoungersWithTable);
            setParentFreeSpots(data.numberOfSunLoungersWithTable);
            return;
        }

        if (service === Services.MINIPOOL) {
            setFreeSpots(data.numberOfMinipool);
            setParentFreeSpots(data.numberOfMinipool);
            return;
        }

        setParentFreeSpots(0);

    }, [data]);

    const isCorrectService = (service === Services.ENTRY_TICKET)
        || (service === Services.SUN_LOUNGERS)
        || (service === Services.SUN_LOUNGERS_WITH_TABLE)
        || (service === Services.MINIPOOL)

    if (! data) return null;

    if (isCorrectService && freeSpots > 0) {
        return <Text size="1">Кількість доступних місць: {freeSpots}</Text>
    }

    if (isCorrectService && freeSpots <= 0) {
        return <Text size="1">Немає вільних місць на обрану дату</Text>
    }

    return null;
}
