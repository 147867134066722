import React, {useState} from "react";
import {Box, Button, Card, Flex, Inset, Link, Strong, Text} from "@radix-ui/themes";
import {redirect, useNavigate} from "react-router-dom";
import {SlideshowLightbox} from "lightbox.js-react";


interface BarEntryProps {
    heroImage: string,
    galleryImages: object[],
    name: string,
    description: string,
    menuPDF: string,
}
export default function BarEntry(props: BarEntryProps) {

    const {
        heroImage, name, description, menuPDF, galleryImages,
    } = props;

    let [isOpen, setIsOpen] = useState(false);

    return (
        <Box>
            <Card size="2" variant="classic" className="dashboard-feature__card">
                <Inset clip="padding-box" side="top" pb="current">
                    <img
                        onClick={() => setIsOpen(true)}
                        src={heroImage}
                        alt="Фото бару"
                        style={{
                            display: 'block',
                            objectFit: 'cover',
                            width: '100%',
                            height: 140,
                            backgroundColor: 'var(--gray-5)',
                        }}
                    />
                </Inset>
                <Text as="div" size="4" weight="bold">
                    { name }
                </Text>
                <Text as="div" size="1" color="gray">
                    { description }
                </Text>
                <Flex mt='6' justify='end'>
                    <a href={menuPDF}>
                        <Button>
                            Переглянути меню
                        </Button>
                    </a>
                </Flex>
            </Card>
            <SlideshowLightbox
                images={galleryImages}
                showThumbnails={false}
                open={isOpen}
                lightboxIdentifier="lbox1"
                onClose={() =>{setIsOpen(false)}}>
            </SlideshowLightbox>
        </Box>
    )
}
