import React from "react";
import HRLogo from "../HR_Logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {Flex} from "@radix-ui/themes";
import GoBack from "../shared/GoBack";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname !== '/') {
        return (
            <Flex justify='between' align='center' px='4' pt='4'>
                <GoBack />
                <img src={HRLogo} height='32px' onClick={() => navigate('/')}/>
            </Flex>
        )
    }

    return (
        <Flex justify='center'>
            <img src={HRLogo} style={{ paddingTop: 16 }} height='32px' onClick={() => navigate('/')}/>
        </Flex>
    )
}
