import {Box, Button, Dialog, Flex, IconButton, Inset, Spinner, Text, TextField} from "@radix-ui/themes";
import React, {useEffect, useRef, useState} from "react";
import QrScanner from 'qr-scanner';
import {XMarkIcon} from "@heroicons/react/24/solid";
import {CLIENT_APP_URL} from "../../api/config";
import {useNavigate} from "react-router-dom";

interface ScanQRModalProps {
    open: boolean,
    setOpen: (state: boolean) => void,
}

export default function ScanQRModal(props: ScanQRModalProps) {

    const {open, setOpen} = props;

    const navigate = useNavigate();

    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    const onScanSuccess = (result: QrScanner.ScanResult) => {
        if (result.data.includes(`${CLIENT_APP_URL}/link-payment`)) {
            const url = result.data.split('/');
            const reservationID = url[url.length -1];

            navigate(`/link-payment/${reservationID}`);
            setOpen(false);
        }
    };

    // Fail
    const onScanFail = (err: string | Error) => {
        // 🖨 Print the "err" to browser console.
        console.log('Error message form customs', err);
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
            });
        }

        return () => {
            if (!videoEl?.current) {
                scanner.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (open) {
            scanner.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        } else {
            scanner.current?.stop();
        }

    }, [open, scanner.current]);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    return (
        <>
            <Box
                display={open ? 'block' : 'none'}
                position="absolute"
                top="0"
                width='100vw'
                height="100dvh"
                style={{ background: 'var(--color-overlay)'}}
            >
                <Box position='absolute' top='24px' right='24px' style={{zIndex: '60'}}>
                    <IconButton size="4" variant="outline" style={{ boxShadow: "none" }}>
                        <XMarkIcon width='100%' height='100%' onClick={() => setOpen(false)} color='white' />
                    </IconButton>
                </Box>
                <Flex width='100%' height='100%' direction="column" justify="center" style={{zIndex: '50'}}>
                    <Box position='absolute' top='49%' left='49%' >
                        <Spinner size="3" />
                    </Box>
                    <video ref={videoEl} style={{ height: '100dvh', objectFit: 'cover' }}></video>
                </Flex>
            </Box>
        </>
    )
}
