import { emptySplitApi } from '../../api/api';

export const registerAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        registerUser: builder.mutation<any, { phone: string, email: string, name: string, surname: string }>({
            query(data) {

                return {
                    url: `/register`,
                    method: 'POST',
                    body: {
                        phone: data.phone,
                        email: data.email,
                        firstName: data.name,
                        lastName: data.surname,
                    },
                };
            },

            // async onQueryStarted(args, { dispatch, queryFulfilled }) {
            //     try {
            //         await queryFulfilled;
            //         dispatch(addFlashMessage('success', 'Question is posted successfully'));
            //         dispatch(resetCreateQuestionForm());
            //     } catch {
            //         dispatch(addFlashMessage('error', 'Something went wrong. Please try again.'));
            //     }
            // },

            //invalidatesTags: () => [''],
        }),
    }),
});

export const {
    useRegisterUserMutation,
} = registerAPI;
