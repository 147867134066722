import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {useNavigate} from "react-router-dom";
import {content} from "../../helpers/content";
import {Services} from "../../types/services";

export default function AHomeRoomsList() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>A-Home</Heading>

            <Flex mt='4' direction='column' gap='4'>

                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.AHOME_THREE_COMFORT].heroImage}
                    galleryImages={content[Services.AHOME_THREE_COMFORT].galleryImages}
                    description={content[Services.AHOME_THREE_COMFORT].description}
                    service={Services.AHOME_THREE_COMFORT}
                    priceDescription="+500 грн за кожну людину (макс 4)"
                />

                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.AHOME_THREE_STANDARD].heroImage}
                    galleryImages={content[Services.AHOME_THREE_STANDARD].galleryImages}
                    description={content[Services.AHOME_THREE_STANDARD].description}
                    service={Services.AHOME_THREE_STANDARD}
                    priceDescription="+500 грн за кожну людину (макс 4)"
                />

                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.AHOME_SIX_COMFORT].heroImage}
                    galleryImages={content[Services.AHOME_SIX_COMFORT].galleryImages}
                    description={content[Services.AHOME_SIX_COMFORT].description}
                    service={Services.AHOME_SIX_COMFORT}
                    priceDescription="+500 грн за кожну людину (макс 8)"
                />

                <EntertainmentEntry
                    isHotelBooking
                    heroImage={content[Services.AHOME_SIX_STANDARD].heroImage}
                    galleryImages={content[Services.AHOME_SIX_STANDARD].galleryImages}
                    description={content[Services.AHOME_SIX_STANDARD].description}
                    service={Services.AHOME_SIX_STANDARD}
                    priceDescription="+500 грн за кожну людину (макс 8)"
                />
                {/*<EntertainmentEntry*/}
                {/*    image=""*/}
                {/*    name="Стандарт 3-місний"*/}
                {/*    description=""*/}
                {/*    callToAction="Обрати"*/}
                {/*    onClickCTA={() => navigate('/accommodation/ahome/three')}*/}
                {/*    isHotelBooking*/}
                {/*/>*/}
                {/*<EntertainmentEntry*/}
                {/*    image=""*/}
                {/*    name="Стандарт 6-місний"*/}
                {/*    description=""*/}
                {/*    callToAction="Обрати"*/}
                {/*    onClickCTA={() => navigate('/accommodation/ahome/six')}*/}
                {/*    isHotelBooking*/}
                {/*/>*/}
            </Flex>
        </Flex>
    )
}
