import React from "react";
import {Button, Flex, Heading} from "@radix-ui/themes";
import {useNavigate} from "react-router-dom";

export default function NotFound() {

    const navigate = useNavigate();

    return (
        <Flex width="100%" position="relative" justify="center">
            <Flex direction="column" gap="2" align="center" mt="40dvh">
                <Heading size="4">Не знайдено</Heading>
                <Button onClick={() => navigate("/")}>Повернутись на дашборд</Button>
            </Flex>
        </Flex>
    )
}
