export const maskConfig = {
    mask: '+380 (__) ___-__-__',
    replacement: { _: /\d/ },
    showMask: true,
}

// export const maskFormatConfig = {
//     mask: '+___ (__) ___-__-__',
//     replacement: { _: /\d/ },
// }
