import React, {useEffect, useRef, useState} from "react";
import {Badge, Box, Button, Card, Flex, Link, Text, Tooltip} from "@radix-ui/themes";
import {getManagerPhoneByService, getServiceNameByEnum, getServiceType, ServiceType} from "../../types/services";
import {getLabelsByReservationStatus, ReservationStatusString} from "./helpers";
import {QuestionMarkCircleIcon} from "@heroicons/react/16/solid";
import {Reservation} from "./types";
import {useMakePaymentAfterScanQuery} from "../booking/bookingAPI";
import PrivatPayImage from "../../assets/24_Pay_Black.svg";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {useAppDispatch} from "../../store/hooks";
import {setCancelledReservation} from "./reservationsSlice";

interface ReservationsEntryProps {
    reservation: Reservation,
}

export default function ReservationsEntry(props: ReservationsEntryProps) {

    const {
        status,
        serviceId,
        dateFrom,
        dateTo,
        amount,
        numberOfSpots,
        comment,
        id,
        promoCode,
    } = props.reservation;

    const paymentFormRef = useRef<HTMLFormElement>(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const {data: paymentData, isLoading} = useMakePaymentAfterScanQuery(
        { reservationID: id.toString() },
        { skip: (! id) || status !== ReservationStatusString.ReadyForPayment}
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (! tooltipOpen) return;

        const timeoutID = setTimeout(() => {
            setTooltipOpen(false);
        }, 3500);

        return () => {
            if (timeoutID) clearTimeout(timeoutID)
        }
    }, [tooltipOpen]);

    const onSubmit = () => {
        setTimeout(() => {
            if (! paymentFormRef.current) {
                console.error('No payment form ref');
                return;
            }
            paymentFormRef.current.submit();
        }, 150); // todo: find better way instead of timeout
    }

    const tooltipLabels = getLabelsByReservationStatus(status);

    const dateStart = new Date(dateFrom).toLocaleDateString();
    const dateEnd = dateTo ? new Date(dateTo).toLocaleDateString() : '';
    const time = new Date(dateFrom).toLocaleTimeString();

    const isHotelBooking = getServiceType(serviceId || null) === ServiceType.ACCOMMODATION;

    const canBeCancelled =
        status === ReservationStatusString.ReadyForPayment ||
        status === ReservationStatusString.InReview
    ;

    const canBePayed = Boolean(paymentData) && status === ReservationStatusString.ReadyForPayment && amount && (amount > 0);

    return (
        <Box>
            <Card size="2" variant="classic" className="dashboard-feature__card">
                <Flex direction="column">
                    <Text as="div" size="4" weight="bold">
                        { getServiceNameByEnum(serviceId) }
                    </Text>

                    <Box mt="2">
                        <Tooltip
                            open={tooltipOpen}
                            content={tooltipLabels.tooltip}
                            delayDuration={300}
                        >
                            <Badge
                                color={tooltipLabels.color}
                                onClick={() => setTooltipOpen(true)}
                            >
                                <QuestionMarkCircleIcon width="12px" height="12px" />
                                {tooltipLabels.label}
                            </Badge>
                        </Tooltip>
                    </Box>

                    <Flex direction="column" mt="3" gap="2">
                        <Flex direction="column">
                            <Text size="1" weight="medium" color="gray">Дата</Text>
                            <Text size="2"><b>{ dateStart } {dateEnd ? `— ${dateEnd}` : ''}</b></Text>
                        </Flex>

                        { time && (!isHotelBooking) && (
                            <Flex direction="column">
                                <Text size="1" weight="medium" color="gray">Час</Text>
                                <Text size="2"><b>{time}</b></Text>
                            </Flex>
                        )}
                        {
                            numberOfSpots > 1 ? (
                                <Flex direction="column">
                                    <Text size="1" weight="medium" color="gray">Кількість</Text>
                                    <Text size="2"><b>{numberOfSpots}</b></Text>
                                </Flex>
                            ) : null
                        }
                        {
                            (Boolean(amount)) ? (
                                <Flex direction="column">
                                    <Text size="1" weight="medium" color="gray">Сума</Text>
                                    <Text size="2"><b>{amount} грн</b></Text>
                                </Flex>
                            ) : null
                        }
                        {
                            promoCode ? (
                                <Flex direction="column">
                                    <Text size="1" weight="medium" color="gray">Промокод</Text>
                                    <Text size="2"><b>{promoCode.code}</b></Text>
                                </Flex>
                            ) : null
                        }
                    </Flex>
                </Flex>
                {
                    comment && <Text size="1" my="1">Коментар менеджера: {comment}</Text>
                }
                {
                    getManagerPhoneByService(serviceId) ? (
                        <Flex my="1" direction="column">
                            <Text size="1">
                                Якщо у вас виникли питання, або з вами не зв'язалися для підтвердження резерву, зателефонуйте нам за номером:
                            </Text>
                            <a href={`tel:${getManagerPhoneByService(serviceId)?.phone}`} style={{ textDecoration: 'none'}}>
                                <Text color="orange" size="2">
                                    {getManagerPhoneByService(serviceId)?.formattedPhone}
                                </Text>
                            </a>
                        </Flex>
                    ) : null
                }
                <Flex mt={(canBePayed || canBeCancelled) ? '6' : '0'} justify="end" align="center" gap="2">
                    {
                        canBeCancelled ? (
                            <Button
                                variant="soft"
                                color="gray"
                                disabled={isLoading}
                                onClick={ () => {
                                    dispatch(setCancelledReservation(id));
                                }}
                            >
                                <XCircleIcon width={16} height={16} /> Скасувати
                            </Button>
                        ) : null
                    }
                    {
                        canBePayed ? (
                            <Button variant="ghost" disabled={isLoading} onClick={onSubmit} >
                                <Flex justify="center" align="center" direction="column">
                                    <img src={PrivatPayImage} style={{ height: 36 }} />
                                </Flex>
                            </Button>
                        ) : null
                    }
                </Flex>
            </Card>
            <div style={{display: 'none'}}>
                <form ref={paymentFormRef} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                    <input type="hidden" name="data" value={paymentData?.data || ''}/>
                    <input type="hidden" name="signature" value={paymentData?.signature || ''}/>
                </form>
            </div>
        </Box>
    )
}
