import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "../Entertainment/EntertainmentEntry";
import {useNavigate} from "react-router-dom";
import {Services} from "../../types/services";

export default function AHomeRoomsThreeList() {

    return (
        <Flex direction='column' pt='4'>
            <Heading>A-Home: 3-місний</Heading>

            <Flex mt='4' direction='column' gap='4'>
                {/*<EntertainmentEntry*/}
                {/*    image=""*/}
                {/*    name="Стандарт 3-місний"*/}
                {/*    description=""*/}
                {/*    service={Services.AHOME_THREE_STANDARD}*/}
                {/*    isHotelBooking*/}
                {/*/>*/}
                {/*<EntertainmentEntry*/}
                {/*    image=""*/}
                {/*    name="Стандарт 3-місний + сауна і чани"*/}
                {/*    description=""*/}
                {/*    service={Services.AHOME_THREE_COMFORT}*/}
                {/*    isHotelBooking*/}
                {/*/>*/}
            </Flex>
        </Flex>
    )
}
