import {Box, Button, Callout, Dialog, Flex, Link, Text, TextField} from "@radix-ui/themes";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    resetForm,
    setBookingDate,
    setBookingEndDate,
    setNumberOfSpots,
    setPromoCode,
} from "./bookingSlice";
import {getQuantityLabelByServiceEnum, getServiceNameByEnum, Services} from "../../types/services";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import LoginForm from "../Login/LoginForm";
import RegisterForm from "../Register/RegisterForm";
import {useCreateBookingMutation, useCreateFastBookingMutation, useGetActivePromoCodeMutation} from "./bookingAPI";
import {FastBookingRequestPayload} from "./types";
import {useNavigate} from "react-router-dom";
import PrivatPayImage from '../../assets/24_Pay_Black.svg';
import FreeSpots from "../../shared/FreeSpots";
import {setPage} from "../Reservations/reservationsSlice";
import {useNotifyBotMutation} from "../../api/api";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {debounce} from "../../helpers/utils";

// TODO: calculate free spots if number booked is > 1;
export default function BookingModal() {

    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);

    const [showPromoCodeForm, setShowPromoCodeForm] = useState(false);
    const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState('');
    const [promoCodeDiscount, setPromoCodeDiscount] = useState('');

    // note: can be incorrect buz cashed from older service
    const [freeSpots, setFreeSpots] = useState(0);
    const [validationMessage, setValidationMessage] = useState('');

    const paymentFormRef = useRef<HTMLFormElement>(null);
    const [paymentData, setPaymentData] = useState('');
    const [paymentSignature, setPaymentSignature] = useState('');

    const {
        showBookingModal: show,
        isHotelBooking,
        date,
        endDate,
        numberOfSpots,
        selectedService,
        promoCode,
    } = useAppSelector(state => state.booking);

    const navigate = useNavigate();

    const { isUserLoggedIn } = useAppSelector(state => state.authentication);

    const dispatch = useAppDispatch();

    const { data: profile } = useGetProfileQuery(null);

    const [checkPromoCodeRequest] = useGetActivePromoCodeMutation();

    const [
        createBookingRequest,
        { isLoading: isCreating, isError: isCreateError }
    ] = useCreateBookingMutation();

    const [
        createFastBookingRequest,
        { isLoading: isFastCreating, isError: isFastCreateError}
    ] = useCreateFastBookingMutation();

    const [notifyBotRequest] = useNotifyBotMutation();

    const isFastBooking =
        selectedService === Services.ENTRY_TICKET ||
        selectedService === Services.SUN_LOUNGERS ||
        selectedService === Services.SUN_LOUNGERS_WITH_TABLE ||
        selectedService === Services.MINIPOOL
    ;

    useEffect(() => {
        if (isUserLoggedIn) {
            setShowLoginForm(false);
            setShowRegisterForm(false);
        } else {
            setShowRegisterForm(true);
        }
    }, [isUserLoggedIn]);

    useEffect(() => { // reset validation message on service changed
        setValidationMessage('');
    }, [selectedService]);

    const onCheckPromoCode = useCallback(debounce(async (code, serviceID) => {
        if (! code || ! serviceID) return;

        const res = await checkPromoCodeRequest({code, serviceID});

        // @ts-expect-error
        if (res.error) {
            // @ts-expect-error
            setPromoCodeErrorMessage(res.error?.data?.error || 'Помилка перевірки QR');
        }

        // @ts-expect-error
        if (res.data) {
            // @ts-expect-error
            const dateTo = new Date(res.data.expirationDate);
            // @ts-expect-error
            const message = `Знижка -${res.data.discountAmount}грн. Діє до ${dateTo.toLocaleDateString()}`
            setPromoCodeDiscount(message)
        }
    }, 1200), [])

    const onSubmit = async () => {
        if (selectedService === undefined || selectedService === null) return;

        let payload = {
            serviceId: selectedService,
            dateFrom: date,
            numberOfSpots,
            promoCode,
        }

        if (isHotelBooking) {
            payload = {
                serviceId: selectedService,
                dateFrom: `${date}T00:00:00Z`,
                // @ts-expect-error
                dateTo: endDate ? `${endDate}T00:00:00Z` : null,
                numberOfSpots,
                promoCode,
            }
        }

        // validate fast booking before sending request to the bot
        if (isFastBooking) {
            const hasFreeSpots = freeSpots >= numberOfSpots;

            if (! hasFreeSpots) {
                setValidationMessage('Недостатньо вільних місць на обрану дату');
                return;
            }
        }

        try {
            const res = await notifyBotRequest({
                serviceId: selectedService,
                firstName: profile?.firstName || '',
                lastName: profile?.lastName || '',
            })
        } catch (e) {
            // do not fail if error
            console.log('Booking bot request did fail');
        }

        if (isFastBooking) {
            const response = await createFastBookingRequest(payload as FastBookingRequestPayload);

            // @ts-expect-error
            if (response.data) {
                // @ts-expect-error
                setPaymentData(response.data.data);
                // @ts-expect-error
                setPaymentSignature(response.data.signature);
                setTimeout(() => {
                    if (! paymentFormRef.current) {
                        console.error('No payment form ref');
                        return;
                    }
                    paymentFormRef.current.submit();
                }, 150); // todo: find better way instead of timeout
            }
            return;
        }

        // continue general booking flow
        const response = await createBookingRequest(payload);
        // @ts-ignore
        if (! response.error) {
            navigate('/reservations-list');
            dispatch(setPage(1));
            dispatch((resetForm()));
            setPromoCodeDiscount('');
            setPromoCodeErrorMessage('');
        }
    }

    return (
        <Dialog.Root open={show}>
            <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()} maxWidth="740px">
                <Dialog.Title>{getServiceNameByEnum(selectedService)}</Dialog.Title>
                {
                    selectedService === Services.ENTRY_TICKET && (
                        <Callout.Root size='1' mb='4' style={{ alignItems: 'center' }}>
                            <Callout.Icon>
                                <InformationCircleIcon width="16" height="16" />
                            </Callout.Icon>
                            <Callout.Text size='1'>
                                Увага! Бронювання будь-якого варіанту проживання або катання на вейках також дає право вʼїзду на територію.
                            </Callout.Text>
                        </Callout.Root>
                    )
                }

                <Flex direction="column" gap="4">
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            { isHotelBooking ? 'Дата заїзду' : 'Час і дата'}
                        </Text>
                        <TextField.Root
                            type={ isHotelBooking ? 'date' : 'datetime-local'}
                            value={date}
                            min={
                                isHotelBooking
                                    ? new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]
                                    : new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"))
                            }
                            onChange={(e) => {
                                dispatch(setBookingDate(e.target.value));
                                setValidationMessage('');
                            }}
                            step={isHotelBooking ? undefined : '15'}
                        />
                    </label>
                    {
                        isHotelBooking && (
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Дата виїзду
                                </Text>
                                <TextField.Root
                                    type="date"
                                    value={endDate}
                                    min={new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]}
                                    onChange={(e) => dispatch(setBookingEndDate(e.target.value))}
                                    step={isHotelBooking ? undefined : '15'}
                                />
                            </label>
                        )
                    }
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Кількість {getQuantityLabelByServiceEnum(selectedService)}
                        </Text>
                        <TextField.Root
                            type='number'
                            value={numberOfSpots}
                            min={1}
                            onChange={(e) => {
                                dispatch(setNumberOfSpots(parseInt(e.target.value)));
                                setValidationMessage('');
                            }}
                        />
                        {
                            selectedService && date && <FreeSpots setParentFreeSpots={setFreeSpots} service={selectedService} date={date} />
                        }
                    </label>

                    {
                        showPromoCodeForm ? (
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Промокод (за наявності)
                                </Text>
                                <TextField.Root
                                    type='text'
                                    value={promoCode}
                                    onChange={(e) => {
                                        dispatch(setPromoCode(e.target.value));
                                        setValidationMessage('');
                                        setPromoCodeErrorMessage('');
                                        setPromoCodeDiscount('');
                                        onCheckPromoCode(e.target.value, selectedService);
                                    }}
                                />
                                <Text size="1" weight="bold" color="gold">{promoCodeDiscount}</Text>
                              <Text size="1" color="red">{promoCodeErrorMessage}</Text>
                            </label>
                        ) : (
                            <Link size="2" onClick={() => setShowPromoCodeForm(true)}>
                                Маєте промокод?
                            </Link>
                        )
                    }

                    {
                        (! isUserLoggedIn) && date && (
                            <>
                                <Flex direction='column' align='center' mt='4' gap='2'>

                                    {/*<Separator />*/}
                                    <Box>
                                        <Link
                                            size='2'
                                            style={{ textDecoration: showRegisterForm ? 'underline' : '' }}
                                            onClick={() => {setShowLoginForm(true); setShowRegisterForm(false)}}
                                        >
                                            Увійти
                                        </Link>
                                        <Text size='2'> або </Text>
                                        <Link
                                            size='2'
                                            style={{ textDecoration: showLoginForm ? 'underline' : '' }}
                                            onClick={() => {setShowLoginForm(false); setShowRegisterForm(true)}}
                                        >
                                            швидко забронювати
                                        </Link>
                                    </Box>
                                    <Box width='100%'>
                                        {
                                            showLoginForm && <LoginForm
                                                showSMSHint
                                                showForgotPassword={false}
                                                afterLogin={() => {}}
                                            />
                                        }
                                        {
                                            showRegisterForm && <RegisterForm
                                                showShortForm
                                                onSuccess={() => {
                                                    setShowRegisterForm(false);
                                                    setShowLoginForm(true);
                                                }}
                                            />
                                        }
                                    </Box>
                                </Flex>
                            </>
                        )
                    }
                </Flex>

                <Flex gap="3" mt="6" justify="end" align="center">
                    <Button variant="soft" color="gray" onClick={() => {
                        dispatch(resetForm());
                        setPromoCodeDiscount('');
                        setPromoCodeErrorMessage('');
                    }}>
                        Назад
                    </Button>
                    {
                        (! isFastBooking) && (
                            <Button disabled={(! isUserLoggedIn) || isCreating} onClick={onSubmit}>
                                Забронювати
                            </Button>
                        )
                    }
                    {
                        (isFastBooking) && (
                            <Button variant="ghost" disabled={(! isUserLoggedIn) || isFastCreating} onClick={onSubmit}>
                                <Flex justify="center" align="center">
                                    <img src={PrivatPayImage} style={{ height: 36 }} />
                                </Flex>
                            </Button>
                        )
                    }
                </Flex>
                <Flex justify="end" mt="1">
                    {
                        (isCreateError || isFastCreateError) && <Text size="1" color="red">Сталася помилка. Спробуйте знову</Text>
                    }
                    {
                        Boolean(validationMessage) ? <Text size="1" color="red">{validationMessage}</Text> : null
                    }
                </Flex>

                <div style={{display: 'none'}}>
                    <form ref={paymentFormRef} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                        <input type="hidden" name="data" value={paymentData}/>
                        <input type="hidden" name="signature" value={paymentSignature}/>
                    </form>
                </div>
            </Dialog.Content>
        </Dialog.Root>
    )
}
