import React from "react";
import {Box, Flex, Heading} from "@radix-ui/themes";
import { iframeText } from './publicRulesDoc';

// convert with cloudconvert
export default function PublicRules() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Правила комплексу</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%' height='70dvh'>
                    <iframe title="Публічна оферта" style={{ width: '100%', height: '100%' }} srcDoc={iframeText} />
                </Box>
            </Flex>
        </Flex>
    )
}
