import {PaginatedResponse} from "../../types/pagination";
import {Reservation} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReservationStatusString} from "./helpers";

export interface ReservationsSlice extends Omit<PaginatedResponse<Reservation>, 'data' | 'total' | 'totalPages'> {
    status: ReservationStatusString | null,
    dateFrom: string | null,
    cancelReservationModal: {
        open: boolean,
        reservationId: number | null,
    }
}

const initialState: ReservationsSlice = {
    page: 1,
    perPage: 10,
    // totalPages: 1,
    // total: 1,
    status: null,
    dateFrom: null,
    cancelReservationModal: {
        open: false,
        reservationId: null,
    }
};

export const reservationsSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        // setTotalPages: (state, action: PayloadAction<number>) => {
        //     state.totalPages = action.payload;
        // },
        // setTotal: (state, action: PayloadAction<number>) => {
        //     state.total = action.payload;
        // },
        setStatus: (state, action: PayloadAction<ReservationStatusString | null>) => {
            state.status = action.payload;
            state.page = 1;
        },
        resetCancelReservationModal: (state) => {
            state.cancelReservationModal = initialState.cancelReservationModal;
        },
        setCancelledReservation: (state, action: PayloadAction<number>) => {
            state.cancelReservationModal = {
                open: true,
                reservationId: action.payload,
            };
        },
    }
});

export const {
    setPage,
    // setTotalPages,
    // setTotal,
    setStatus,
    resetCancelReservationModal,
    setCancelledReservation,
} = reservationsSlice.actions;

export default reservationsSlice;
