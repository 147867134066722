import React, {useEffect, useState} from "react";
import {Button, Flex, Link, Text, TextField} from "@radix-ui/themes";
import {useMask} from "@react-input/mask";
import {useLoginUserMutation} from "./loginAPI";
import {useNavigate} from "react-router-dom";
import {useFormValidation} from "../../helpers/validation";
import {rulesLoginForm, validationErrorState, ValidationKeys} from "./loginValidationRules";
import {formatPhoneAfterMask, getPhoneNumberFromStore, savePhoneNumberToStore} from "../../helpers/utils";
import {maskConfig} from "../../helpers/mask";

interface LoginFormProps {
    afterLogin?: () => void;
    showForgotPassword?: boolean;
    showSMSHint?: boolean;
}

export default function LoginForm(props: LoginFormProps) {
    const inputRef = useMask(maskConfig);

    const {
        afterLogin,
        showForgotPassword= true,
        showSMSHint,
    } = props;

    const [phoneNumber, setPhoneNumber] = useState('+380');
    const [password, setPassword] = useState('');

    const [loginUserRequest, { isLoading, isError, error }] = useLoginUserMutation();

    const navigate = useNavigate();

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        rulesLoginForm,
        validationErrorState,
        {
            password,
            phoneNumber: formatPhoneAfterMask(phoneNumber),
        },
    );

    useEffect(() => {
        (async () => {
            const number = await getPhoneNumberFromStore();
            if (number) {
                setPhoneNumber(number);
            }
        })()
    }, []);

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        const response = await loginUserRequest({
            phone: formatPhoneAfterMask(phoneNumber),
            password,
        });

        // @ts-ignore
        if (response.data && response.data.accessToken) {
            await savePhoneNumberToStore(phoneNumber);
            afterLogin && afterLogin();
        }
    };

    let errorMessage = 'Сталася помилка. Спробуйте знову';
    // @ts-expect-error
    if (error?.data && error?.data?.error && typeof error?.data?.error === 'string') {
        // @ts-expect-error
        const sysError = error?.data?.error.trim().toLowerCase();
        if (sysError === 'invalid password') {
            errorMessage = 'Невірний пароль'
        }
    }

    return (
        <Flex direction='column' gap='4'>
            <label>
                <Text as="div" size="2" mb="1" weight="bold">
                    Номер телефону
                </Text>
                <TextField.Root
                    type="tel"
                    ref={inputRef}
                    value={phoneNumber}
                    placeholder="Ваш номер"
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        onValidationInputChange('phoneNumber');
                    }}
                />
                <Text size="1" color="red">{validationState.phoneNumber.errorMessage}</Text>
            </label>
            <label>
                <Text as="div" size="2" mb="1" weight="bold">
                    Пароль { showSMSHint && <Text size="1" weight="regular">(надійде у смс)</Text> }
                </Text>
                <TextField.Root
                    placeholder="*****"
                    type="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        onValidationInputChange('password');
                    }}
                />
                <Text size="1" color="red">{validationState.password.errorMessage}</Text>
            </label>
            <Flex justify={showForgotPassword ? 'between' : 'end'} align="center">
                {
                    showForgotPassword && <Link size="2" onClick={() => navigate('/forgot-password')}>Забули пароль?</Link>
                }
                <Button
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    Увійти
                </Button>
            </Flex>
            <Flex justify="end">
                {
                    isError ? (
                        <Text size="1" color="red">{errorMessage}</Text>
                    ) : null
                }
            </Flex>
        </Flex>
    )
}
