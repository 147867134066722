import React, {ReactElement} from "react";
import {Box, Card, Flex, Text} from "@radix-ui/themes";
import Dancing from "../images/dancing.png";
import {BoxProps} from "@radix-ui/themes/src/components/box";
import {useNavigate} from "react-router-dom";
import {isArray} from "util";

interface DashboardFeatureProps {
    containerProps: BoxProps,
    title: string,
    description?: string | string[],
    image?: string,
    rotateOpposite?: true,
    column?: boolean,
    linkTo?: string,
    customContent?: ReactElement,
}
export default function DashboardFeature(props: DashboardFeatureProps) {

    const {
        containerProps,
        title,
        description,
        image,
        rotateOpposite,
        column,
        linkTo = '/',
        customContent
    } = props;

    const descArray = Array.isArray(description) ? description : [description];

    const navigate = useNavigate();

    return (
        <Box {...containerProps} className="dashboard-feature" onClick={() => navigate(linkTo)}>
            <Card size='3' variant="classic" className="dashboard-feature__card">
                <Flex gap="3" align="center" justify="between" direction={column ? 'column-reverse' : undefined}>
                    <Flex direction="column" align={column ? 'center' : 'start'}>
                        <Text as="div" size="4" weight="bold">
                            { title }
                        </Text>
                        {
                            descArray.map((d, idx) => (
                                <Text key={idx} as="div" size="1" color="gray">
                                    { d }
                                </Text>
                            ))
                        }
                    </Flex>
                    {
                        image && (
                            <img
                                src={image}
                                alt={title}
                                className={`dashboard-feature__image${rotateOpposite ? '--rotate-minus' : ''} ${column ? 'no-rotate' : ''}`}
                                width='48px'
                                height='48px'
                            />
                        )
                    }
                </Flex>
                <Box mt="2">
                    { customContent }
                </Box>
            </Card>
        </Box>
    )
}
