import { configureStore } from '@reduxjs/toolkit'
import bookingSlice from '../features/booking/bookingSlice'
import {botApi, emptySplitApi} from "../api/api";
import authSlice from "../features/Login/authSlice";
import profileSlice from "../features/Profile/profileSlice";
import reservationsSlice from "../features/Reservations/reservationsSlice";

const store = configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        [botApi.reducerPath]: botApi.reducer,

        [authSlice.name]: authSlice.reducer,
        [profileSlice.name]: profileSlice.reducer,

        [bookingSlice.name]: bookingSlice.reducer,
        [reservationsSlice.name]: reservationsSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
