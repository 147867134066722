import React from "react";
import {Callout, Flex, Grid, Heading, Link} from "@radix-ui/themes";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import DashboardFeature from "./components/DashboardFeature";

import Dancing from "./images/dancing.png";
import House from "./images/house.png";
import BarCounter from "./images/bar-counter.png";
import Sauna from "./images/sauna.png";
import Inn from "./images/inn.png";
import PlaneTickets from "./images/plane-tickets.png";
import Chatbot from "./images/chatbot.png";

import './index.css'
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/16/solid";


export default function Dashboard() {

    const navigate = useNavigate();

    const { isUserLoggedIn } = useAppSelector(state => state.authentication);

    const { data } = useGetProfileQuery(null);

    return (
        <Flex direction="column" py='2'>
            <Heading>Вітаємо у Lake Resort!</Heading>

            {
                (! isUserLoggedIn) && (
                    <Callout.Root mt='4' size='1' style={{ alignItems: 'center' }}>
                        <Callout.Icon>
                            <InformationCircleIcon width="16" height="16" />
                        </Callout.Icon>
                        <Callout.Text size='1' onClick={() => navigate('/login')}>
                            <Link style={{ textDecoration: "underline" }}>Увійдіть</Link> для використання усього функціоналу
                        </Callout.Text>
                    </Callout.Root>
                )
            }

            {
                (data && (data?.role !== Roles.Customer)) && (
                    <Callout.Root mt='4' size='1' style={{ alignItems: 'center' }}>
                        <Callout.Icon>
                            <InformationCircleIcon width="16" height="16" />
                        </Callout.Icon>
                        <Callout.Text size='1'>
                            Ви увійшли як адміністратор. Для використання додатку як користувач будь-ласка увійдіть у інший обліковий запис
                        </Callout.Text>
                    </Callout.Root>
                )
            }

            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Розваги"
                description="Спорт? Спокій? У нас Ви знайдете магічне поєднання веселощів"
                linkTo="/entertainment-list"
                image={Dancing}
            />

            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Бари"
                description="Загляньте у надзвичайні бари з найкращими барменами і смачною кухнею"
                linkTo="/bars-list"
                image={BarCounter}
                rotateOpposite
            />

            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Релакс"
                description="Відпочиньте душею та тілом: наші послуги зроблять ваш релакс незабутнім"
                linkTo="/relax-list"
                image={Sauna}
            />

            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Розміщення"
                description="Знайдіть свій ідеальний варіант відпочинку, ми пропонуємо різноманітні варіанти для Вашої зручності!"
                linkTo="/accommodation"
                image={Inn}
                rotateOpposite
            />

            <DashboardFeature
                containerProps={{mt: '4',  display: 'none'}}
                title="Паркування (білети)"
                description="Переглянути свій білет"
                image={PlaneTickets}
            />

            <Grid gap='4' width='auto' columns="2">
                <a href="https://t.me/LakeResortBot" rel="noopener" >
                    <DashboardFeature
                        containerProps={{mt: '4'}}
                        title="Підтримка"
                        image={Chatbot}
                        rotateOpposite
                        column
                        description="Телеграм"
                    />
                </a>

                <a href="tel:+380689709999" rel="noopener" >
                    <DashboardFeature
                        containerProps={{mt: '4'}}
                        title="Нерухомість"
                        // description="Можливість переїхати"
                        image={House}
                        column
                        description="068 970-99-99"
                    />
                </a>
            </Grid>

            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Наша адреса"
                description={[
                    "Київська обл., с. Березівка, котеджне містечко Нова Березівка, вул. Паризька 62-А.",
                    "Години роботи: з 9:00 до 23:00"
                ]}
                customContent={(
                    <a
                        href="https://maps.app.goo.gl/YXY1FgdniA5QGq1LA"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                    >
                        <Link size="1">
                            <Flex align="center" gap="1">
                                <ArrowTopRightOnSquareIcon width="16" height="16" />
                                Відкрити в Google Maps
                            </Flex>
                        </Link>
                    </a>
                )}
            />
        </Flex>
    )
}
