import React from "react";
import {Box, Flex, Heading, Card} from "@radix-ui/themes";
import {useNavigate} from "react-router-dom";
import RegisterForm from "./RegisterForm";

export default function Register() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Реєстрація</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    <Card size="2" variant="classic" className="dashboard-feature__card">
                        <RegisterForm onSuccess={() => navigate('/register/success')} />
                    </Card>
                </Box>
            </Flex>
        </Flex>
    )
}
