import React, {useEffect, useState} from 'react';
import {Theme, ThemePanel} from '@radix-ui/themes';
import {initLightboxJS} from 'lightbox.js-react'
import Layout from "./Layout/Layout";

import 'lightbox.js-react/dist/index.css'
import '@radix-ui/themes/styles.css';
import './index.css';


// TODO: BUY lightbox.js licence
function App() {
    const [mode, setMode] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        initLightboxJS("Insert your License Key here", "individual");
    }, []);

    useEffect(() => {
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'))
        ;

        setMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);

  return (
      <Theme
          accentColor="orange"
          grayColor="mauve"
          appearance={mode}
          radius="medium"
          panelBackground="translucent"
      >
          <Layout />
          {/*<ThemePanel />*/}
      </Theme>
  );
}

export default App;
