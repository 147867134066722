import {Button, Dialog, Flex} from "@radix-ui/themes";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {resetCancelReservationModal, setPage} from "./reservationsSlice";
import {useCancelReservationMutation} from "./reservationsAPI";

export default function CancelReservationModal() {
    const { open, reservationId} = useAppSelector((state: RootState) => state.reservations.cancelReservationModal);

    const dispatch = useAppDispatch();

    const [cancelReservationRequest] = useCancelReservationMutation();

    return (
        <Dialog.Root open={open}>

            <Dialog.Content maxWidth="450px">
                <Dialog.Title>Скасувати резервацію?</Dialog.Title>
                <Dialog.Description size="2" mb="4">
                    Активувати скасовану резервацію неможливо
                </Dialog.Description>

                <Flex gap="3" mt="6" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray" onClick={() => dispatch(resetCancelReservationModal())}>
                            Назад
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button
                            color="red"
                            onClick={async () => {
                                dispatch(resetCancelReservationModal());

                                if (! reservationId) return;

                                await cancelReservationRequest({ reservationID: reservationId });
                                dispatch(setPage(1));
                            }}
                        >
                            Скасувати
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    )
}
