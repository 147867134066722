import React from "react";
import {Flex, Heading} from "@radix-ui/themes";
import EntertainmentEntry from "./EntertainmentEntry";
import {getServiceNameByEnum, Services} from "../../types/services";
import {content} from "../../helpers/content";
import {useNavigate} from "react-router-dom";
import {STATIC_URL} from "../../api/config";

export default function EntertainmentList() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Дозвілля</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <EntertainmentEntry
                    heroImage={content[Services.HORSE_RIDE].heroImage}
                    galleryImages={content[Services.HORSE_RIDE].galleryImages}
                    description={content[Services.HORSE_RIDE].description}
                    durationText={content[Services.HORSE_RIDE].durationText}
                    service={Services.HORSE_RIDE}
                />
                <EntertainmentEntry
                    heroImage={content[Services.WAKEBOARDING].heroImage}
                    galleryImages={content[Services.WAKEBOARDING].galleryImages}
                    description={content[Services.WAKEBOARDING].description}
                    durationText={content[Services.WAKEBOARDING].durationText}
                    service={Services.WAKEBOARDING}
                />
                <EntertainmentEntry
                    heroImage={content[Services.FISHING].heroImage}
                    galleryImages={content[Services.FISHING].galleryImages}
                    description={content[Services.FISHING].description}
                    service={Services.FISHING}
                    priceDescriptionBold="Вхідний квиток необхідно забронювати окремо у розділі «Білети»."
                />
                <EntertainmentEntry
                    heroImage={`${STATIC_URL}/images/sup_and_kayak/people_on_the_sup_hero_image.jpg`}
                    name='Сапи, каяки, катамарани'
                    description="Відчуй пригоду на озері! 🌊 Орендуй сапи та каяки у «Lake Resort». Легко, безпечно, незабутньо! 🛶✨ Забронюй зараз і створи ідеальні спогади! 📸"
                    callToAction="Обрати"
                    onClickCTA={() => navigate('/sap-kayak-list')}
                    showPrices={false}
                />
            </Flex>
        </Flex>
    )
}
