import React from "react";
import {Box, Flex, Heading, Card, Button} from "@radix-ui/themes";
import LoginForm from "./LoginForm";
import {useNavigate} from "react-router-dom";

export default function Login() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Вхід</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    <Card size="2" variant="classic" className="dashboard-feature__card">
                        <LoginForm afterLogin={() => navigate('/')} />
                    </Card>
                </Box>
            </Flex>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    <Card size="2" variant="classic" className="dashboard-feature__card">
                        <Heading size="4">Ще немає акаунту?</Heading>
                        <Flex width='100%' justify="start" mt="4">
                            <Button
                                onClick={() => navigate('/register')}
                            >
                                Створити новий акаунт
                            </Button>
                        </Flex>
                    </Card>
                </Box>
            </Flex>
        </Flex>
    )
}
