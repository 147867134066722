import React, {useState} from "react";
import {Button, Checkbox, Flex, Link, Text, TextField} from "@radix-ui/themes";
import {useMask} from "@react-input/mask";
import {useFormValidation} from "../../helpers/validation";
import {rulesLoginForm, validationErrorState, ValidationKeys} from "./registerValidationRules";
import {formatPhoneAfterMask, savePhoneNumberToStore} from "../../helpers/utils";
import {useNavigate} from "react-router-dom";
import {useRegisterUserMutation} from "./registerAPI";
import {maskConfig} from "../../helpers/mask";

interface RegisterFormProps {
    onSuccess?: () => void,
    showShortForm?: boolean,
}

export default function RegisterForm(props: RegisterFormProps) {
    const inputRef = useMask(maskConfig);

    const { onSuccess, showShortForm = false } = props;

    const [phoneNumber, setPhoneNumber] = useState('+380');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [acceptRules, setAcceptRules] = useState(false);

    const navigate = useNavigate();

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        rulesLoginForm,
        validationErrorState,
        {
            name,
            email,
            surname,
            acceptRules,
            phoneNumber: formatPhoneAfterMask(phoneNumber),
        },
    );

    const [registerRequest, {

        isLoading,
        isError
    }] = useRegisterUserMutation();

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        const response = await registerRequest({
            phone: formatPhoneAfterMask(phoneNumber),
            name,
            surname,
            email
        });

        // @ts-ignore
        if (! response.error) {
            await savePhoneNumberToStore(phoneNumber);
            onSuccess && onSuccess();
        }
    };

    return (
        <Flex direction='column' gap='4'>
            <label>
                <Text as="div" size="2" mb="1" weight="bold">
                    Імʼя
                </Text>
                <TextField.Root
                    placeholder="Маркіян"
                    value={name}
                    type="text"
                    onChange={(e) => {
                        setName(e.target.value)
                        onValidationInputChange('name');
                    }}
                />
                <Text size="1" color="red">{validationState.name.errorMessage}</Text>
            </label>
            {
                (! showShortForm) && (
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Прізвище
                        </Text>
                        <TextField.Root
                            placeholder="Вишеголовко"
                            value={surname}
                            type="text"
                            onChange={(e) => {
                                setSurname(e.target.value)
                                onValidationInputChange('surname');
                            }}
                        />
                        <Text size="1" color="red">{validationState.surname.errorMessage}</Text>
                    </label>
                )
            }
            <label>
                <Text as="div" size="2" mb="1" weight="bold">
                    Номер телефону
                </Text>
                <TextField.Root
                    type="tel"
                    ref={inputRef}
                    value={phoneNumber}
                    placeholder="Ваш номер"
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        onValidationInputChange('phoneNumber');
                    }}
                />
                <Text size="1" color="red">{validationState.phoneNumber.errorMessage}</Text>
            </label>
            {
                (! showShortForm) && (
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            E-mail
                        </Text>
                        <TextField.Root
                            type="email"
                            value={email}
                            placeholder="example@gmail.com"
                            onChange={(e) => {
                                setEmail(e.target.value)
                                onValidationInputChange('email');
                            }}
                        />
                        <Text size="1" color="red">{validationState.email.errorMessage}</Text>
                    </label>
                )
            }
            <Text as="label" size="1">
                <Flex gap="2">
                    <Checkbox
                        defaultChecked={false}
                        onClick={() => setAcceptRules(! acceptRules)}
                    />
                    <Text>
                        З {' '}
                        <Link
                            size="1"
                            onClick={() => navigate('/public-rules')}
                            style={{ textDecoration: 'underline' }}
                        >
                            правилами комплексу
                        </Link>
                        {' '} та {' '}
                        <Link
                            size="1"
                            onClick={() => navigate('/public-offer')}
                            style={{ textDecoration: 'underline' }}
                        >
                            публічною офертою
                        </Link>
                        {' '} ознаймлений(-а) та погоджуюсь
                    </Text>
                </Flex>
                <Text size="1" color="red">{validationState.acceptRules.errorMessage}</Text>
            </Text>
            <Flex justify="end" align="center">
                <Button
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    Створити акаунт
                </Button>
            </Flex>
            <Flex justify="end">
                {
                    isError && <Text size="1" color="red">Сталася помилка. Спробуйте знову. Якщо вже зареєстровані - використовуйте форму для входу.</Text>
                }
            </Flex>
        </Flex>
    )
}
