import { emptySplitApi } from '../../api/api';
import {GetAccessZonesResponse, GetReservationsListRequest, GetReservationsListResponse, Reservation} from "./types";
import {PaginatedResponse} from "../../types/pagination";

export const reservationsAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getReservations: builder.query<PaginatedResponse<Reservation>, GetReservationsListRequest>({
            query(params) {
                const {pageSize, pageNumber, status, dateFrom} = params;

                const request = { pageSize, pageNumber };
                // @ts-ignore
                if (status) request.status = status;
                // @ts-ignore
                if (dateFrom) request.dateFrom = dateFrom;

                return {
                    url: '/myReservations',
                    params: request,
                };
            },
            transformResponse: (response: GetReservationsListResponse, meta, arg) => {
                const totalPages = Math.ceil(response.totalCount / arg.pageSize);

                return {
                    total: response.totalCount,
                    perPage: arg.pageSize,
                    page: arg.pageNumber,
                    totalPages: totalPages > 0 ? totalPages : 1,
                    data: response.reservations,
                }
            },
            providesTags: (result, error, arg) => [
                { type: 'ReservationsList', ...arg },
            ],
        }),
        cancelReservation: builder.mutation<any, { reservationID: number }>({
            query(data) {
                return {
                    url: `/cancelReservation`,
                    method: 'DELETE',
                    body: { reservationId: data.reservationID },
                };
            },
            invalidatesTags: ['ReservationsList', 'SpotsCalculation'],
        }),
    }),
});

export const {
    useGetReservationsQuery,
    useCancelReservationMutation,
} = reservationsAPI;
