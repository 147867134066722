import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQueryWithReAuth} from './baseQueryApp';
import {BOT_URL} from "./config";
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {FastBookingRequestPayload, FastBookingResponse} from "../features/booking/types";

// initialize an empty api service
export const emptySplitApi = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'baseApi',

    tagTypes: [
        'CustomerProfile',
        'ReservationsList',
        'SpotsCalculation',
        'AccessZonesInfo',
    ],
    endpoints: () => ({}),
});

export const botApi = createApi({
    reducerPath: 'botAPI',
    baseQuery: fetchBaseQuery({ baseUrl: BOT_URL }),
    endpoints: (builder) => ({
        notifyBot: builder.mutation<any, {serviceId: number, firstName?: string, lastName?: string}>({
            query(data) {
                return {
                    url: '/',
                    method: 'POST',
                    body: data
                };
            },
        }),
    }),
});

export const {
    useNotifyBotMutation,
} = botApi;
