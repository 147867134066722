import React from "react";
import {Box, Button, Card, Flex, Heading, Text} from "@radix-ui/themes";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

export default function SuccessfulRegisterScreen() {

    const navigate = useNavigate();

    return (
        <Flex direction='column' pt='4'>
            <Heading>Реєстрація пройшла успішно!</Heading>

            <Flex mt='6' direction='column' gap='4'>
                <Box width='100%'>
                    <Card size="2" variant="classic" className="dashboard-feature__card">
                        <Flex justify="center">
                            <CheckCircleIcon color="var(--teal-9)" width="36px" height="36px" />
                        </Flex>
                       <Box mt="4">
                           <Text size="2">
                               Очікуйте на смс до трьох хвилин з паролем для входу.
                           </Text>
                           <Box mt="1">
                               <Text size="2">
                                   Якщо СМС не надходить, скористайтесь функцією "відновити пароль".
                               </Text>
                           </Box>

                           <Flex justify="center" mt="4">
                               <Button size="3" onClick={() => navigate('/login')}>
                                   Увійти
                               </Button>
                           </Flex>
                       </Box>
                    </Card>
                </Box>
            </Flex>
        </Flex>
    );
}
