import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query';
// import type { RootState } from './store';
import { API_URL } from './config';
import { Mutex } from 'async-mutex';
import {RootState} from "../store/store";
import {logoutIntercept, setNewTokensIntercept} from "./refreshTokenActions";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).authentication;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  credentials: 'include',
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { token, refreshToken } = (api.getState() as RootState).authentication;

        const refreshResult = await baseQuery(
            {
              url: '/refreshtoken',
              method: 'POST',
              body: {
                refreshToken: refreshToken,
                accessToken: token,
              },
            },
            api,
            extraOptions,
        );

        if (refreshResult.data) {
          // @ts-ignore TBD: type response correctly
          api.dispatch(setNewTokensIntercept(refreshResult.data));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logoutIntercept());
        }
      } finally {
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
