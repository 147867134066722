import React from "react";
import {Box, Button, Flex, Text} from "@radix-ui/themes";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import {useNavigate, useNavigation} from "react-router-dom";

export default function GoBack() {

    const navigate = useNavigate();
    return (
        <Button variant='ghost' onClick={() => navigate(-1)}>
            <ChevronLeftIcon width="20" height="20" />
            <Text weight='bold'>назад</Text>
        </Button>
    )
}
